import { atom } from 'recoil';

// type ModalState = {
//   isModalOpen: boolean;
//   setModalOpen: (state) => void;
// };

export interface RemoveActivityModalType {
  isModalOpen: boolean;
  params?: any;
}

export const RemoveActivityModalState = atom<RemoveActivityModalType>({
  key: 'RemoveActivityModalState',
  default: {
    isModalOpen: false,
    params: {},
  },
});
