import { countries } from 'countries-list';
import { Card, CardHeader, Col, Row, Table } from 'reactstrap';
import { AwardPointEvent } from 'recoil-state/activityState';
import { convertTimeToISOString } from 'utils/dateFormatters';

interface Props {
  awardPointEvents: AwardPointEvent[];
}

enum TriggerType {
  CHECKPOINTCOLLECTION = 'CheckpointCollection',
  DISTANCETRIGGER = 'DistanceTrigger',
  TUTORIALCOMPLETITION = 'TutorialCompletion',
  LEGACYPOINTS = 'LegacyPoints',
  MANUALADJUSTMENT = 'ManualAdjustment',
}

function convertTriggerType(type) {
  switch (type) {
    case 0:
      return TriggerType.CHECKPOINTCOLLECTION;
    case 1:
      return TriggerType.DISTANCETRIGGER;
    case 2:
      return TriggerType.TUTORIALCOMPLETITION;
    case 3:
      return TriggerType.LEGACYPOINTS;
    case 4:
      return TriggerType.MANUALADJUSTMENT;
    default:
      return TriggerType.CHECKPOINTCOLLECTION;
  }
}

export function ActivityAwardPoints({ awardPointEvents }: Props) {
  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <Row>
          <Col>
            <h3 className="mb-0">Award Points</h3>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Event Time</th>
            <th scope="col">Points</th>
            <th scope="col">AwardPoint Trigger Type</th>
            <th scope="col">Country</th>
            <th scope="col">Disqualified</th>
          </tr>
        </thead>
        <tbody>
          {awardPointEvents?.map((evt, idx) => (
            <tr key={idx}>
              <td>{convertTimeToISOString(evt.eventTimestamp)}</td>
              <td>{evt.points}</td>
              <td>{convertTriggerType(evt.awardPointTriggerType)}</td>
              <td>{countries[evt.country?.toUpperCase()]?.name}</td>
              <td>{evt.disqualified ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}
