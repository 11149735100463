import { ActivityStats, DailyStat } from 'model/ApiData';
import Service from './index';

const apiRequest = Service.apiRequest;

interface GetDashboardDataParams {
  dailyActivities: ActivityStats[];
  dailyUsers: DailyStat[];
  dailyMissionTicketRedemptions: DailyStat[];
  totalActivities: number;
  totalAccountCreations: number;
  totalDistance: number;
  totalTicketRedemptions: number;
  totalMissionUsers: number;
  missionId?: string;
  from: Date;
  to: Date;
}

async function getDashboardData(params: any) {
  try {
    const response = await apiRequest.get<GetDashboardDataParams>(`dashboard/`, { params });

    return response.data;
  } catch (e) {
    return {
      dailyActivities: [],
      dailyUsers: [],
      dailyMissionTicketRedemptions: [],
      totalActivities: 0,
      totalDistance: 0,
      totalAccountCreations: 0,
      totalTicketRedemptions: 0,
      totalMissionUsers: 0,
      from: new Date(),
      to: new Date(),
    };
  }
}

export default {
  getDashboardData,
};
