import {
  LeaderboardTypeOption,
  leaderboardTypeOptions,
  LeaderboardTypeSelector,
} from 'elements/LeaderboardTypeSelector';
import { LoadingSpinnerTableRow } from 'elements/LoadingSpinnerTableRow';
import { MissionSelector } from 'elements/MissionSelector';
import { LeaderboardType } from 'model/LeaderboardType';
import { Team } from 'model/Team';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { CardBody, CardHeader, Col, Media, Row, Table } from 'reactstrap';
import MissionService, { LeaderboardRecord } from 'services/MissionService';

interface CSVExportData {
  userId: string;
  teamId: string;
  name: string;
  value: string;
}

type TeamLeaderboardProps = {
  team: Team;
};

export function TeamLeaderboard({ team }: TeamLeaderboardProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [leaderboardRecords, setLeaderboardRecords] = useState<LeaderboardRecord[]>([]);
  const [csvData, setCsvData] = useState<CSVExportData[]>([]);

  const [missionId, setMissionId] = useState<string | null>(null);
  const [leaderboardType, setLeaderboardType] = useState<LeaderboardTypeOption>(
    leaderboardTypeOptions.find((_) => _.value === 'points'),
  );

  const handleOnChangeLeaderboardType = useCallback(
    (_leaderboardType: LeaderboardType, label: string) => {
      setLeaderboardType({ label, value: _leaderboardType });
    },
    [],
  );

  const getTeamUserLeaderboard = useCallback(
    async (missionId: string) => {
      setLoading(true);

      const response = await MissionService.getMissionLeaderboard(missionId, {
        teamId: team.teamId,
        leaderboardType: leaderboardType.value,
        page: 1,
        pageSize: team.totalMembers,
      });

      setLeaderboardRecords(response);
      setCsvData(
        response.map((_) => ({
          userId: _.userId,
          teamId: _.teamId,
          name: `${_.firstName} ${_.lastName}`,
          value: _.value,
        })),
      );
      setLoading(false);
    },
    [leaderboardType.value, team.teamId, team.totalMembers],
  );

  useEffect(() => {
    if (missionId) {
      getTeamUserLeaderboard(missionId);
    }
  }, [getTeamUserLeaderboard, missionId]);

  return (
    <>
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="6">
            <MissionSelector
              onMissionUpdate={setMissionId}
              displayLabel={true}
              defaultValueLabel={missionId || 'Select Mission'}
            />
          </Col>
          <Col xs="6">
            <LeaderboardTypeSelector
              onChange={handleOnChangeLeaderboardType}
              displayLabel={true}
              defaultValueLabel={'Select Mission'}
            />
          </Col>
        </Row>
        {csvData && (
          <Row className="mt-4">
            <Col className="text-left">
              <CSVLink
                data={csvData}
                filename={`team_${leaderboardType.value}_leaderboard_${moment().toISOString()}.csv`}
              >
                Export CSV
              </CSVLink>
            </Col>
          </Row>
        )}
      </CardHeader>
      <CardBody>
        <Table className="align-items-center" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Image</th>
              <th scope="col">User</th>
              <th scope="col">{leaderboardType.label}</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {loading && <LoadingSpinnerTableRow />}
            {leaderboardRecords.map((res, idx) => {
              return (
                <tr
                  key={idx}
                  onClick={(e) => history.push(`/admin/users/${res.userId}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{res?.position}</td>
                  <th scope="row">
                    <Media className="align-items-center">
                      <a className="avatar rounded-circle mr-3" href="#pablo">
                        <img alt="..." src={res?.profileImage} />
                      </a>
                    </Media>
                  </th>
                  <td>
                    {res?.firstName} {res?.lastName}
                  </td>
                  <td>{res.value}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </>
  );
}
