import Firebase from 'components/Firebase';
import { AuthenticationModal } from 'components/Modals/AuthenticationModal';
import AdminLayout from 'layouts/Admin';
import AuthLayout from 'layouts/Auth';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AuthTokenState } from 'recoil-state/authState';
import UserService from 'services/UserService';

const AuthHoldingPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { token: authToken } = useRecoilValue(AuthTokenState);
  const setAuthTokenState = useSetRecoilState(AuthTokenState);
  const [user, loading] = useAuthState(Firebase.auth);
  const { pathname } = useLocation();
  const [previousRoute, setPreviousRoute] = useState('/admin/index');
  const [failedUserId, setFailedUserId] = useState('');

  useEffect(() => {
    if (!['/auth/login', '/'].includes(pathname)) {
      setPreviousRoute(pathname);
    }
  }, [pathname]);

  const performProfileCheckAndLogin = async () => {
    const userProfile = await UserService.getUserProfile({ userId: user?.uid });

    if (userProfile == null) {
      await UserService.createUserProfile({
        firstName: user.displayName,
        lastName: '',
        email: user.email,
        profileImage: '',
      });
    }

    UserService.authenticateUser()
      .then((response) => {
        user.getIdToken().then((token) => {
          setAuthTokenState({ token });
        });
      })
      .catch((e) => {
        UserService.requestRoleUpgrade(user?.uid);
        setModalOpen(!isModalOpen);
        setFailedUserId(user?.uid);
        Firebase.doSignOut();
      });
  };

  useEffect(() => {
    if (user) {
      performProfileCheckAndLogin();
    }
    // eslint-disable-next-line
  }, [user, setAuthTokenState]);

  // Don't display the page if we're still loading the authentication state
  if (loading || (user !== null && authToken === null)) {
    return <></>;
  }

  return (
    <>
      <Switch>
        {user && authToken ? (
          <>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to={previousRoute} />
          </>
        ) : (
          <>
            <Route path="/auth/login" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="/" to="/auth/login" />
          </>
        )}
      </Switch>
      <AuthenticationModal isOpen={isModalOpen} toggleModal={setModalOpen} userId={failedUserId} />
    </>
  );
};

export default AuthHoldingPage;
