import Header from 'components/Headers/Header';
import SocialFeed from 'components/Social/SocialFeed';
import { Config } from 'config';
import { MissionSelector } from 'elements/MissionSelector';
import { useState } from 'react';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import SocialService from 'services/SocialService';

export interface Props {
  streamFeedGroup: string;
  streamFeedUserId: string;
}

export function Feed() {
  const [missionId, setMissionId] = useState<string | null>(null);

  const streamFeedGroup = Config.streamFeedGroup ?? 'mission';
  const streamFeedUserId = missionId ?? Config.streamFeedUserId;

  // const [newPostUiVisible, setNewPostUiVisible] = useState(false);

  const clearAllUserFeeds = async () => {
    try {
      var confirmed = window.confirm(
        'Warning, this will clear all activities from the social feeds for all users. This cannot be undone. Proceed?',
      );

      if (confirmed) {
        await SocialService.clearAllUserFeeds();
      }
    } catch (e) {
      alert(
        'This feature is not enabled for your application. Please contact District to enable it.',
      );
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow" style={{ backgroundColor: '#F8F8F8' }}>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <MissionSelector
                      onMissionUpdate={setMissionId}
                      displayLabel={true}
                      defaultValueLabel={'Default Mission'}
                    />
                  </Col>
                  <Col style={{ display: 'flex', justifyContent: 'right' }}>
                    {/* <Button onClick={() => setNewPostUiVisible(!newPostUiVisible)}>Add New Post</Button> */}
                    <Button color="danger" onClick={clearAllUserFeeds}>
                      Clear All Activities
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ width: '600px', margin: '0 auto' }}>
                <SocialFeed
                  streamFeedGroup={streamFeedGroup}
                  streamFeedUserId={streamFeedUserId}
                  // displayNewPostUI={newPostUiVisible}
                  displayNewPostUI={false}
                />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
