import { AwardPointEvent } from 'recoil-state/activityState';
import { date, object, string } from 'yup';

export type ActivityEditFormState = {
  startTime: Date;
  endTime: Date;
  distance: string;
};

export const defaults = {
  startTime: '',
  endTime: '',
  distance: '',
};

export function getSchema() {
  const startTime = date().required('First Name is required.');
  const endTime = date().required('First Name is required.');
  const distance = string().required('distance is required');

  return object().shape({
    startTime,
    endTime,
    distance,
  });
}

export function getPointsCounts(awardPointEvents: AwardPointEvent[]) {
  return awardPointEvents?.reduce(
    (acc, { points, awardPointTriggerType, disqualified }) => {
      return {
        totalPointsCounts: acc.totalPointsCounts + (disqualified ? 0 : points),
        checkPointCollectionCounts:
          awardPointTriggerType === 0
            ? acc.checkPointCollectionCounts + points
            : acc.checkPointCollectionCounts,
        distanceTriggerPointsCounts:
          awardPointTriggerType === 1
            ? acc.distanceTriggerPointsCounts + points
            : acc.distanceTriggerPointsCounts,
      };
    },
    {
      totalPointsCounts: 0,
      distanceTriggerPointsCounts: 0,
      checkPointCollectionCounts: 0,
    },
  );
}
