import { MissionTicket } from 'model/Ticket';
import { useHistory } from 'react-router-dom';
import { Button, CardHeader, Col, Row, Table } from 'reactstrap';
import UserService from 'services/UserService';

interface Props {
  tickets: MissionTicket[];
  userId: string;
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}

export function UserDetailsMissionTickets({ tickets, userId, isModalOpen, setModalOpen }: Props) {
  const history = useHistory();
  const openRedeemTicketModal = () => {
    setModalOpen(!isModalOpen);
  };
  const unRedeemTicket = (e, ticketCode) => {
    e.stopPropagation();
    UserService.unRedeemTicket({ userId, ticketCode })
      .then((res) => {})
      .catch((err) => {});
  };
  return (
    <>
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8"></Col>
          <Col className="text-right" xs="4">
            <Button color="primary" onClick={openRedeemTicketModal} size="sm">
              Redeem
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Ticket Code</th>
            <th scope="col">Date Redeemed</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {tickets?.map((ticket, idx) => (
            <tr
              key={idx}
              onClick={(e) => history.push(`/admin/tickets/${ticket.ticketCode}`)}
              style={{ cursor: 'pointer' }}
            >
              <td>{ticket.ticketCode}</td>
              <td>{ticket.ticketCode}</td> {/** TODO: Redeemed date */}
              <td>
                <Button
                  size="sm"
                  onClick={(e) => unRedeemTicket(e, ticket.ticketCode)}
                  color="danger"
                  type="button"
                >
                  Unredeem
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
