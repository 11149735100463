export enum WearableProvider {
  GARMIN = 'Garmin',
  SUUNTO = 'Suunto',
  POLAR = 'Polar',
  APPLE = 'Apple',
  FITBIT = 'Fitbit',
  GPX = 'GPX',
  STRAVA = 'Strava',
}

export enum ActivitySport {
  OnFoot = 0,
  Cycling = 1,
  Other = 2,
  Workout = 3,
  GuidedActivity = 4,
  Swimming = 5,
}
