import { ActivitySport } from 'model/enums';
import { LeaderboardType } from 'model/LeaderboardType';
import { PaginationQueryParams } from 'model/PaginationQueryParams';
import { PAGE_SIZE } from 'variables/constants';
import Service from './index';

export interface LeaderboardParams extends PaginationQueryParams {
  missionId?: string;
  teamId?: string;
  fromDate?: string;
  toDate?: string;
  leaderboardType?: LeaderboardType;
  minDistance?: number;
  gender?: number;
  region?: string;
  ticketCategory?: string;
  ageGroup?: string;
  selectedResults?: boolean;
  activitySport?: ActivitySport;
  userId?: string;
}

export interface LeaderboardRecord {
  userId: string;
  profileImage: string;
  firstName: string;
  lastName: string;
  value: string;
  position: string;
  teamId: string;
  teamTypeId: string;
  parentTeamId: string;
}

async function getMissionLeaderboard(missionId: string, params: LeaderboardParams) {
  try {
    const response = await Service.apiRequest.get<LeaderboardRecord[]>(
      `missions/${missionId}/leaderboard`,
      {
        params: {
          page: 1,
          pageSize: PAGE_SIZE,
          ...params,
        },
      },
    );

    return response.data;
  } catch (e) {
    return [];
  }
}

export default {
  getMissionLeaderboard,
};
