import { AppFeedback } from 'model/AppFeedback';
import PasswordResetLinkDTO from 'model/PasswordResetLinkDTO';
import { User } from 'model/User';
import { ActivitySport, ActivityType, DisqualificationReason } from 'utils/converters';
import Service from './index';

export interface SearchUserParams {
  query: string;
}

export interface EditUserParams {
  userId?: string;
}
export interface EditBodyParams {
  firstName: string;
  lastName: string;
  profileImage: string;
  dateOfBirth: string;
  gender: 0;
}

export interface GetUserParams {
  userId: string;
}

export interface CreateUserProfileParams {
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string;
}

export interface UserProfile {
  userId?: string;
  firstName: string;
  lastName: string;
  profileImage: string;
}

export interface ActivitySummaries {
  activityId: string;
  activityType: ActivityType;
  activitySport: ActivitySport;
  startTime: string;
  endTime: string;
  distance: number;
  steps: number;
  duration: number;
  suspicious: true;
  disqualified: true;
  disqualificationReason: DisqualificationReason;
  socialActivityId: string;
  wearableProvider: number;
  selectedResult: true;
  userId: string;
  country: string;
  points: number;
  checkpointCollections: number;
}

export interface UserTeam {
  teamId: 'string';
  title: 'string';
  profileImage: 'string';
  missionId: 'string';
  captainUserId: 'string';
  teamChatId: 'string';
  externalTeamSlug: 'string';
  externalTeamId: 'string';
  users: User[];
}

interface unRedeemTicketParams {
  userId: string;
  ticketCode: string;
}

export interface UserSearchResult {
  userId: string;
  firstName: string;
  lastName: string;
  profileImage: string;
}

async function authenticateUser() {
  try {
    const response = await Service.apiRequest.get(`users/authenticate/`);

    return response.data;
  } catch (e) {
    throw e;
  }
}

async function getAllUsers(params: any) {
  try {
    const response = await Service.apiRequest.get<User[]>(`users`, { params });

    return response.data;
  } catch (e) {
    return [];
  }
}

async function searchUsers(params: SearchUserParams): Promise<UserSearchResult[]> {
  try {
    const response = await Service.apiRequest.get(`users/search/`, { params });

    return response.data;
  } catch (e) {
    return [];
  }
}

async function getUserProfile(params: GetUserParams) {
  try {
    const response = await Service.apiRequest.get<User>(`users/${params.userId}`, { params });

    return response.data;
  } catch (e) {
    return null;
  }
}

async function createUserProfile(params: CreateUserProfileParams) {
  try {
    const response = await Service.apiRequest.post<User>(`users`, params);

    return response.data;
  } catch (e) {
    return null;
  }
}

async function editUserProfile(params: EditUserParams, data: any) {
  try {
    const response = await Service.apiRequest.put<User>(`users/${params.userId}`, { ...data });
    return response.data;
  } catch (e) {
    return null;
  }
}

async function getUserActivitySummaries(params: GetUserParams) {
  try {
    const response = await Service.apiRequest.get<ActivitySummaries[]>(
      `users/${params.userId}/activitySummaries`,
    );

    return response.data;
  } catch (e) {
    return null;
  }
}

async function getUserTeams(params: { userId: string }) {
  try {
    const response = await Service.apiRequest.get<UserTeam[]>(`users/${params.userId}/teams`, {
      params,
    });

    return response.data;
  } catch (e) {
    return null;
  }
}

async function unRedeemTicket(params: unRedeemTicketParams) {
  try {
    const response = await Service.apiRequest.delete(
      `users/${params.userId}/tickets/${params.ticketCode}`,
    );

    return response.data;
  } catch (e) {
    return null;
  }
}

async function makeUserAdmin(params: { userId: string }) {
  try {
    const response = await Service.apiRequest.put(`users/${params.userId}/role?role=3`);

    return response.data;
  } catch (e) {
    return null;
  }
}

async function disqualifyUser(params: any) {
  try {
    const response = await Service.apiRequest.post<PasswordResetLinkDTO>(
      `users/${params.userId}/disqualify`,
    );
    return response.data;
  } catch (e) {
    return null;
  }
}

async function mergeUsers(fromUserId: string, toUserId: string, deleteAfterMerge: boolean) {
  try {
    const response = await Service.apiRequest.post<number>(
      `users/${toUserId}/mergeFrom/${fromUserId}?deleteAfterMerge=${deleteAfterMerge}`,
    );
    return response.status;
  } catch (e) {
    return null;
  }
}

async function getPasswordResetLink(userId: string) {
  try {
    const response = await Service.apiRequest.get<PasswordResetLinkDTO>(
      `users/${userId}/passwordResetLink`,
    );
    return response.data;
  } catch (e) {
    return null;
  }
}

async function requestRoleUpgrade(userId: string) {
  try {
    const response = await Service.apiRequest.post(`users/${userId}/role/upgradeRequests?role=3`);
    return response.data;
  } catch (e) {
    return null;
  }
}

async function getAppFeedback(userId: string) {
  try {
    const response = await Service.apiRequest.get<AppFeedback[]>(`users/${userId}/appFeedback`);
    return response.data;
  } catch (e) {
    return [];
  }
}

async function deleteUser(userId: string) {
  try {
    const response = await Service.apiRequest.delete(`users/${userId}`);
    return response.data;
  } catch (e) {
    return [];
  }
}

export default {
  authenticateUser,
  getUserProfile,
  createUserProfile,
  getAllUsers,
  searchUsers,
  editUserProfile,
  getUserActivitySummaries,
  getUserTeams,
  unRedeemTicket,
  makeUserAdmin,
  disqualifyUser,
  mergeUsers,
  getPasswordResetLink,
  requestRoleUpgrade,
  getAppFeedback,
  deleteUser,
};
