import { Config } from 'config';
import { MissionSelector } from 'elements/MissionSelector';
import PaginationRow from 'elements/PaginationRow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CardHeader, Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { useMissions } from 'services/ContentfulService';
import { PAGE_SIZE } from 'variables/constants';

interface ActivitiesHeaderProps {
  setMinDistance?: (distance: number) => void;
  setStartDate?: (date: Date) => void;
  setEndDate?: (date: Date) => void;
  startDate?: Date;
  endDate?: Date;
  setMissionFilter?: (missionId: string) => void;
  onFilterChange?: () => void;
  minDistance?: number;
  activityCount?: number;
  page?: number;
  setPage?: (page: number) => void;
  setCustomActivityFilter?: (isCustomActivityFilterEnabled: boolean) => void;
  isCustomActivityFilterEnabled?: boolean;
}

export function ActivitiesHeader({
  setMinDistance,
  setStartDate,
  setEndDate,
  setMissionFilter,
  startDate,
  endDate,
  onFilterChange,
  minDistance,
  activityCount,
  page,
  setPage,
  setCustomActivityFilter,
  isCustomActivityFilterEnabled,
}: ActivitiesHeaderProps) {
  const missions = useMissions();

  const [mission, setMission] = useState<string | null>(null);
  const _onDistanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinDistance && setMinDistance(parseFloat(e.target.value));
  };

  const handleOnChangeCustomActivityFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomActivityFilter && setCustomActivityFilter(e.target.checked);
  };

  const setNextPage = () => {
    if (activityCount === PAGE_SIZE) {
      setPage(page + 1);
    }
  };

  const setPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onFilterChange();
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [minDistance, isCustomActivityFilterEnabled, startDate, endDate]);

  useEffect(() => {
    setMissionFilter(mission);
    if (mission) {
      setStartDate(new Date(missions[mission].startDate));
      setEndDate(new Date(missions[mission].endDate));
    } else {
      setStartDate(moment(new Date()).subtract(7, 'd')['_d']);
      setEndDate(new Date());
    }
  }, [mission, missions, setEndDate, setMissionFilter, setStartDate]);

  const datePickerStyle = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <CardHeader className="border-0">
      <Row>
        <Col>
          <MissionSelector onMissionUpdate={setMission} />

          {/* <Input
            className={"form-control form-control-alternative"}
            id={"input-mission"}
            type={"select"}
            onChange={(e) => setMission(e.target.value)}
          >
            <>
              {missionOptions?.map((opt, idx) => (
                <option key={idx} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </>
          </Input> */}
        </Col>
        <Col sm={6} lg={2}>
          <InputGroup>
            <Input
              placeholder="Min Distance"
              min={0}
              onChange={_onDistanceChange}
              type="number"
              step="1"
            />
          </InputGroup>
        </Col>
        <Col sm={6} lg={3}>
          <div style={datePickerStyle}>
            <label>From:&nbsp;</label>
            <DatePicker
              className="p-2 rounded border"
              onChange={setStartDate}
              selected={startDate}
              dateFormat="yyyy-MM-dd h:mm aa"
              showTimeSelect
            />
          </div>
        </Col>
        <Col sm={6} lg={3}>
          <div style={datePickerStyle}>
            <label>To:&nbsp;</label>
            <DatePicker
              className="p-2 rounded border"
              onChange={setEndDate}
              selected={endDate}
              dateFormat="yyyy-MM-dd h:mm aa"
              showTimeSelect
            />
          </div>
        </Col>
      </Row>
      {Config.displayCustomActivity === 'TRUE' && (
        <Row>
          <Col className="pt-4">
            <FormGroup check>
              <Input onChange={handleOnChangeCustomActivityFilter} type="checkbox" />
              <Label check>Filter custom activities</Label>
            </FormGroup>
          </Col>
        </Row>
      )}
      <PaginationRow
        page={page}
        isFirstPage={page === 1}
        isLastPage={activityCount < PAGE_SIZE}
        setPreviousPage={setPreviousPage}
        setNextPage={setNextPage}
      />
    </CardHeader>
  );
}
