import { LoadingSpinnerTableRow } from 'elements/LoadingSpinnerTableRow';
import PaginationRow from 'elements/PaginationRow';
import { SearchInput } from 'elements/SearchInput';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, Col, Container, Media, Row, Table } from 'reactstrap';
import TeamService from 'services/TeamService';
import { PAGE_SIZE } from 'variables/constants';

export function TeamTable() {
  const history = useHistory();
  const [searchText, setSearchText] = useState<string>();
  const [teams, setTeams] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getTeams = useCallback(() => {
    setLoading(true);
    TeamService.getAllTeams({ page, pageSize: PAGE_SIZE }).then((response) => {
      // TODO: match the response format on the backend side.
      setTeams(response);
      setLoading(false);
    });
  }, [page]);

  const onSearch = () => {
    setLoading(true);
    TeamService.searchTeams({ query: searchText }).then((response) => {
      setTeams(response);
      setLoading(false);
    });
  };

  const setNextPage = () => {
    if (teams.length === PAGE_SIZE) {
      setPage(page + 1);
    }
  };

  const setPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (page > 0) {
      getTeams();
    }
  }, [getTeams, page]);

  return (
    <>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Teams</h3>
                  </Col>
                  <Col lg="6" xl="3">
                    <SearchInput
                      onChange={setSearchText}
                      onSearch={onSearch}
                      value={searchText}
                      placeHolder="Team Name"
                    />
                  </Col>
                </Row>
                {!searchText && (
                  <PaginationRow
                    page={page}
                    isFirstPage={page === 1}
                    isLastPage={teams.length < PAGE_SIZE}
                    setPreviousPage={setPreviousPage}
                    setNextPage={setNextPage}
                  />
                )}
              </CardHeader>
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Profile Image</th>
                    <th scope="col">Team Name</th>
                    <th scope="col">Team ID</th>
                    <th scope="col"># of members</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {loading && <LoadingSpinnerTableRow />}
                  {teams?.map((res, idx) => {
                    let teamInfo = res;
                    /**The below is caused by the different format of response from `/search` and `/teams`
                     * We should need to modify the backend response format in the future.
                     */
                    if (res.team) teamInfo = res.team;
                    return (
                      <tr
                        key={idx}
                        onClick={(e) => {
                          history.push(`/admin/teams/${teamInfo.teamId}`);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <th scope="row">
                          <Media className="align-items-center">
                            <a className="avatar rounded-circle mr-3" href="#pablo">
                              <img alt="..." src={teamInfo.profileImage} />
                            </a>
                          </Media>
                        </th>
                        <td>{teamInfo.title}</td>
                        <td>{teamInfo.teamId}</td>
                        <td>{teamInfo.users.length}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardHeader>
                {!searchText && (
                  <PaginationRow
                    page={page}
                    isFirstPage={page === 1}
                    isLastPage={teams.length < PAGE_SIZE}
                    setPreviousPage={setPreviousPage}
                    setNextPage={setNextPage}
                  />
                )}
              </CardHeader>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
