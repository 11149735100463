import { TextInput } from 'elements/TextInput';
import { Formik } from 'formik';
import { Team } from 'model/Team';
import { Button, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import TeamService from 'services/TeamService';
import { getAddUserSchema } from '../TeamsState';

interface TeamDetailsAddUserFormProps {
  teamId: string;
  setTeam: (team: Team) => void;
}

export function TeamDetailsAddUserForm({ teamId, setTeam }: TeamDetailsAddUserFormProps) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        userId: '',
        ticketCode: '',
      }}
      validationSchema={getAddUserSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values) => {
        TeamService.addUserToTeamByUserId({
          teamId,
          userId: values.userId,
          ticketCode: values.ticketCode,
        })
          .then((team) => {
            // setTeam(team);    // TODO: Response doesn't include team info correctly.

            /**get team info and set it again after adding a user from team.
             * we may use the response above once the response is fixed correctly.
             */
            TeamService.getTeamById({ teamId }).then((response) => {
              setTeam(response);
            });
          })
          .catch((err) => {});
      }}
    >
      {({ dirty, submitForm }) => {
        return (
          <>
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8"></Col>
                <Col className="text-right" xs="4">
                  {dirty && (
                    <Button color="primary" onClick={submitForm} size="sm">
                      Add
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">Add a User to Team</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <TextInput
                        className="form-control-alternative"
                        id="input-team-user"
                        name="userId"
                        label="User Id"
                        placeholder="User Id"
                      />
                    </Col>
                    <Col lg="12">
                      <TextInput
                        className="form-control-alternative"
                        id="input-team-user-ticket-code"
                        name="ticketCode"
                        label="Ticket Code (optional)"
                        placeholder="Ticket Code"
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </>
        );
      }}
    </Formik>
  );
}
