import Service from './index';

async function getWearablesByUserId(params: { userId: string }) {
  try {
    const response = await Service.apiRequest.get(`wearables?userId=${params.userId}`);

    return response.data;
  } catch (e) {
    return null;
  }
}
export default {
  getWearablesByUserId,
};
