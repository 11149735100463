import Header from 'components/Headers/Header';
import { TeamTable } from 'components/Tables/TeamTable';

export function Teams() {
  return (
    <>
      <Header />
      <TeamTable />
    </>
  );
}
