import Chart from 'chart.js';
import classnames from 'classnames';
import Header, { HeaderCard } from 'components/Headers/DashboardHeader';
import { ActivityStats, DailyStat, DashboardStats } from 'model/ApiData';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import DashboardService from 'services/DashboardService';
import { chartOptions, generateGraphOptions, parseOptions } from 'variables/charts';

const selectedTimezone = {
  value: moment.tz.guess(),
};
const startDate = moment().set({ hour: 0, minute: 0, second: 0 }).subtract(31, 'd').toDate();
const toDate = moment().set({ hour: 0, minute: 0, second: 0 }).toDate(); // TODO: Need to confirm the enddate set correctly.

const calculateDelta = (collection: any[], selector: string) => {
  if (collection?.length > 1) {
    let value = parseFloat(
      (
        (collection[collection.length - 1][selector] / collection[collection.length - 2][selector] -
          1) *
        100
      ).toFixed(2),
    );

    if (isNaN(value)) {
      value = 0;
    }

    return collection?.length > 1 ? value : 0;
  } else {
    return 0;
  }
};

export function Dashboard() {
  parseOptions(Chart, chartOptions());

  const [data, setData] = useState<DashboardStats>({
    activities: [],
    newUsers: [],
    ticketRedemptions: [],
    totalActivities: 0,
    totalDistance: 0,
    totalAccountCreations: 0,
    totalTicketRedemptions: 0,
    totalMissionUsers: 0,
  });

  const [activityGraphDataSelector, setActivityGraphDataSelector] = useState<'count' | 'distance'>(
    'count',
  );

  const activityGraphLabels = {
    count: 'Activities',
    distance: 'km',
  };

  const ticketedMission = data.totalTicketRedemptions !== 0;
  const infoCards: HeaderCard[] = [
    {
      title: 'Activities',
      value: data.totalActivities?.toLocaleString(),
      delta: calculateDelta(data.activities, 'count'),
      deltaLabel: 'since yesterday',
      icon: 'fa-running',
    },
    {
      title: 'Distance',
      value: (data.totalDistance / 1000)?.toLocaleString() + 'km',
      delta: calculateDelta(data.activities, 'distance'),
      deltaLabel: 'since yesterday',
      icon: 'fa-ruler',
    },
    {
      title: 'App Users',
      value: data.totalAccountCreations?.toLocaleString() + ' users',
      delta: calculateDelta(data.newUsers, 'count'),
      deltaLabel: 'since yesterday',
      icon: 'fa-users',
    },
    {
      title: ticketedMission ? 'Ticket Redemptions' : 'Mission Users',
      value: ticketedMission
        ? data.totalTicketRedemptions?.toLocaleString() + ' redemptions'
        : data.totalMissionUsers?.toLocaleString() + ' users',
      delta: calculateDelta(data.ticketRedemptions, 'count'),
      deltaLabel: 'since yesterday',
      icon: 'fa-ticket-alt',
    },
  ];

  const getDashboardData = useCallback((missionId?: string) => {
    const filterFrom = moment.tz(startDate, selectedTimezone.value)?.format();
    const filterTo = moment.tz(toDate, selectedTimezone.value)?.format();

    DashboardService.getDashboardData({
      from: filterFrom,
      to: filterTo,
      timezone: selectedTimezone.value,
      missionId,
    }).then((d) => {
      setData({
        activities: d.dailyActivities,
        newUsers: d.dailyUsers,
        ticketRedemptions: d.dailyMissionTicketRedemptions,
        totalActivities: d.totalActivities,
        totalAccountCreations: d.totalAccountCreations,
        totalDistance: d.totalDistance,
        totalTicketRedemptions: d.totalTicketRedemptions,
        totalMissionUsers: d.totalMissionUsers,
      });
    });
  }, []);

  const handleSetMission = (missionId) => {
    if (missionId === '') {
      missionId = null;
    }

    getDashboardData(missionId);
  };

  const processDailyStatForChart = (users: DailyStat[]) => {
    var filteredUsers = users.filter((u) => moment(u.date) > moment(startDate).subtract(1, 'd'));

    const labels = filteredUsers.map((d) => moment(d.date).format('DD/MM'));
    const newData = filteredUsers.map((d) => d.count);

    return {
      labels,
      datasets: [
        {
          label: 'Users',
          data: newData,
        },
      ],
    };
  };

  const processActivitiesForChart = (activities: ActivityStats[]) => {
    const filterdActivities = activities.filter(
      (a) => moment(a.date) > moment(startDate).subtract(1, 'd'),
    );

    const labels = filterdActivities?.map((d) => moment(d.date).format('DD/MM'));
    const newData = filterdActivities?.map((d) => {
      if (activityGraphDataSelector === 'distance') {
        return d[activityGraphDataSelector] / 1000;
      }
      return d[activityGraphDataSelector];
    });

    return {
      labels,
      datasets: [
        {
          label: 'Activities',
          data: newData,
        },
      ],
    };
  };

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return (
    <>
      <Header cards={infoCards} setMission={handleSetMission} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">Overview</h6>
                    <h2 className="mb-0">Activities</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames('py-2 px-3', {
                            active: activityGraphDataSelector === 'count',
                          })}
                          href="#pablo"
                          onClick={(e) => setActivityGraphDataSelector('count')}
                        >
                          <span className="d-none d-md-block">Count</span>
                          <span className="d-md-none">C</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames('py-2 px-3', {
                            active: activityGraphDataSelector === 'distance',
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => setActivityGraphDataSelector('distance')}
                        >
                          <span className="d-none d-md-block">Distance</span>
                          <span className="d-md-none">D</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* <DashboardActivitiesTable activities={data.recentlyCompletedActivities} /> */}
                {/* Chart */}
                <div className="chart">
                  <Bar
                    // @ts-ignore
                    data={processActivitiesForChart(data.activities)}
                    options={generateGraphOptions(activityGraphLabels[activityGraphDataSelector])}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">Overview</h6>
                    <h2 className="mb-0">Ticket Redemptions</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* <DashboardRedeemedTicketsTable tickets={data.recentlyRedeemedTickets} /> */}
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={processDailyStatForChart(data.ticketRedemptions)}
                    options={generateGraphOptions('redemptions')}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
