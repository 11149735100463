import Header from 'components/Headers/Header';
import { UserTable } from 'components/Tables/UserTable';
export function Users() {
  return (
    <>
      <Header />
      <UserTable />
    </>
  );
}
