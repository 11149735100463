import { useField } from 'formik';
import { Col, FormGroup, Input, Label } from 'reactstrap';

interface Props {
  id: string;
  name: string;
  label: string;
  className?: string;
  placeholder?: string;
  error?: string;
  options?: string[];
  readOnly?: boolean;
}

export function CheckBoxInput({ id, name, label, className }: Props) {
  const [field] = useField(name);
  return (
    <FormGroup row>
      <Col>
        <FormGroup check>
          <Input
            className={className}
            id={id}
            name={field.name}
            defaultChecked={field.value}
            onChange={field.onChange}
            type="checkbox"
          />
          <Label check for={id}>
            {label}
          </Label>
        </FormGroup>
      </Col>
    </FormGroup>
  );
}
