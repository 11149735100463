import { atom } from 'recoil';

export interface AuthTokenType {
  token: string;
}

export const AuthTokenState = atom<AuthTokenType>({
  key: 'AuthTokenState',
  default: {
    token: null,
  },
});
