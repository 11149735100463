import { Spinner } from 'reactstrap';

export function LoadingSpinnerTableRow() {
  return (
    <tr>
      <td colSpan={100}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner color="primary">
            <span className="visually-hidden"></span>
          </Spinner>
        </div>
      </td>
    </tr>
  );
}
