import { MakeUserAdminModal } from 'components/Modals/MakeUserAdminModal';
import { SelectInput } from 'elements/SelectInput';
import { TextInput } from 'elements/TextInput';
import { Formik } from 'formik';
import { User } from 'model/User';
import { useState } from 'react';
import { Button, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import UserService from 'services/UserService';
import { dateFormatter } from 'utils/dateFormatters';
import { getSchema } from '../UsersState';

export interface UserDetailsFormProps {
  user: User;
  userId: string;
  setUser: (user: User) => void;
}

export function UserDetailsForm({ user, userId, setUser }: UserDetailsFormProps) {
  const [isMakeUserModalOpen, setMakeUserModalOpen] = useState(false);
  const makeUserAdmin = () => {
    UserService.makeUserAdmin({ userId }).then((response) => {
      setMakeUserModalOpen(!isMakeUserModalOpen);
    });
  };
  return (
    <>
      <MakeUserAdminModal isOpen={isMakeUserModalOpen} toggleModal={setMakeUserModalOpen} />
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...user,
          dateOfBirth: dateFormatter(user.dateOfBirth, 'YYYY-MM-DD'),
          dateCreated: dateFormatter(user.dateCreated, 'YYYY-MM-DD'),
          gender: user.gender,
        }}
        validationSchema={getSchema}
        validateOnChange
        validateOnBlur
        onSubmit={(values) => {
          UserService.editUserProfile(
            { userId },
            {
              firstName: values.firstName,
              lastName: values.lastName,
              profileImage: values.profileImage,
              dateOfBirth: values.dateOfBirth === 'Invalid date' ? null : values.dateOfBirth,
              gender: parseInt(`${values.gender}`, 10), // convert gender to number as Formik makes its value as string.
              email: values.email,
            },
          )
            .then((user) => {
              UserService.getUserProfile({ userId }).then((user) => setUser(user));
            })
            .catch((err) => {});
        }}
      >
        {({ dirty, submitForm, values }) => {
          return (
            <>
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <h6 className="heading-small text-muted mb-4">User information</h6>
                      </Col>
                      <Col className="text-right">
                        <Button color="primary" type="button" onClick={makeUserAdmin} size="sm">
                          Make Admin
                        </Button>
                      </Col>
                      <Col className="text-right" xs="4">
                        {dirty && (
                          <Button color="primary" onClick={submitForm} size="sm">
                            Save
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <TextInput
                          className="form-control-alternative"
                          id="input-user-id"
                          name="userId"
                          label="User ID"
                          placeholder="User ID"
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <TextInput
                          className="form-control-alternative"
                          id="input-first-name"
                          name="firstName"
                          label="First Name"
                          placeholder="First Name"
                        />
                      </Col>
                      <Col lg="6">
                        <TextInput
                          className="form-control-alternative"
                          id="input-last-name"
                          name="lastName"
                          label="Last Name"
                          placeholder="Last Name"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <TextInput
                          className="form-control-alternative"
                          id="input-email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                        />
                      </Col>
                      <Col lg="6">
                        <SelectInput
                          className="form-control form-control-alternative"
                          id="input-gender"
                          name="gender"
                          label="Gender"
                          type="select"
                          options={[
                            { label: 'Male', value: 1 },
                            { label: 'Female', value: 2 },
                          ]}
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                </Form>
              </CardBody>
            </>
          );
        }}
      </Formik>
    </>
  );
}
