import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { RemoveActivityModalState } from 'recoil-state/modalState';
import ActivityService from 'services/ActivityService';

interface RemoveActivityModalProps {
  onDeleteActivity?: () => void;
}

export function RemoveActivityModal({ onDeleteActivity }: RemoveActivityModalProps) {
  const [removeActivityModal, setRemoveActivityModal] = useRecoilState(RemoveActivityModalState);
  const { isModalOpen, params } = removeActivityModal;
  const _removeActivity = () => {
    ActivityService.deleteActivity({ activityId: params.activityId })
      .then((res) => {
        setRemoveActivityModal({ ...removeActivityModal, isModalOpen: !isModalOpen });

        if (onDeleteActivity) {
          onDeleteActivity();
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isModalOpen}
      toggle={() => setRemoveActivityModal({ ...removeActivityModal, isModalOpen: !isModalOpen })}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <strong>Remove Activity</strong>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <p>Are you sure to remove this activity?</p>
            <div className="modal-footer flex-between">
              <Button className="btn-white" color="default" type="button" onClick={_removeActivity}>
                Ok
              </Button>
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() =>
                  setRemoveActivityModal({
                    ...removeActivityModal,
                    isModalOpen: !isModalOpen,
                  })
                }
              >
                Close
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
