import { Config } from 'config';
import { WearableProvider } from 'model/enums';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { useRecoilState } from 'recoil';
import { RemoveActivityModalState } from 'recoil-state/modalState';
import UserService, { ActivitySummaries } from 'services/UserService';
import {
  getActivitySportLabel,
  getActivityTypeLabel,
  getDisqualifiedReasonLabel,
} from 'utils/converters';
import { convertTimeToISOString, secondsFormatter } from 'utils/dateFormatters';

export function convertWearableProvider(provider) {
  switch (provider) {
    case 0:
      return WearableProvider.GARMIN;
    case 1:
      return WearableProvider.SUUNTO;
    case 2:
      return WearableProvider.POLAR;
    case 3:
      return WearableProvider.APPLE;
    case 4:
      return WearableProvider.FITBIT;
    case 5:
      return WearableProvider.GPX;
    case 6:
      return WearableProvider.STRAVA;
    default:
      return 'In-app';
  }
}

function SummariesList({
  userId,
  summaries,
  addActivityModalOpen,
  setAddActivityModalOpen,
}: UserDetailsActivitySummariesProps) {
  const history = useHistory();
  const [removeActivityModal, setRemoveActivityModal] = useRecoilState(RemoveActivityModalState);
  const onAddActivity = () => {
    setAddActivityModalOpen(!addActivityModalOpen);
  };
  const _deleteActivity = (activityId: string) => {
    setRemoveActivityModal({
      isModalOpen: !removeActivityModal.isModalOpen,
      params: { activityId, userId },
    });
  };
  return (
    <>
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8"></Col>
          <Col className="text-right" xs="4">
            <Button color="primary" onClick={onAddActivity} size="sm">
              Add Activity
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Activity ID</th>
            <th scope="col">Date Completed</th>
            {Config.activityType === 'TRUE' && (
              <>
                <th scope="col">Activity Type</th>
                <th scope="col">Activity Sport</th>
              </>
            )}
            <th scope="col">Distance</th>
            <th scope="col">Steps</th>
            <th scope="col">Points</th>
            <th scope="col">Duration</th>
            <th scope="col">Disqualified</th>
            <th scope="col">Disqualified Reason</th>
            <th scope="col">Suspicious</th>
            <th scope="col">WearableProvider</th>
          </tr>
        </thead>
        <tbody>
          {summaries?.map((res, idx) => (
            <tr
              key={idx}
              onClick={(e) => history.push(`/admin/activity/${res.activityId}`)}
              style={{ cursor: 'pointer' }}
            >
              <td>{res.activityId}</td>
              <td>{convertTimeToISOString(res.endTime)}</td>
              {Config.activityType === 'TRUE' && (
                <>
                  <td>{getActivityTypeLabel(res.activityType)}</td>
                  <td>{getActivitySportLabel(res.activitySport)}</td>
                </>
              )}
              <td>{`${res.distance}m`}</td>
              <td>{res.steps}</td>
              <td>{res.points}</td>
              <td>{secondsFormatter(res.duration, 'HH:mm:ss')}</td>
              <td>{res.disqualified ? 'Disqualified' : 'Qualified'}</td>
              <td>{getDisqualifiedReasonLabel(res.disqualificationReason)}</td>
              <td>{res.suspicious ? 'Yes' : 'No'}</td>
              <td>{convertWearableProvider(res.wearableProvider)}</td>
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        e.stopPropagation();
                        _deleteActivity(res.activityId);
                      }}
                    >
                      Remove
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

interface UserDetailsActivitySummariesProps {
  userId?: string;
  summaries?: ActivitySummaries[];
  setSummaries?: (summaries) => void;
  addActivityModalOpen: boolean;
  setAddActivityModalOpen: (modalOpen) => void;
}

export function UserDetailsActivitySummaries({
  userId,
  addActivityModalOpen,
  setAddActivityModalOpen,
  summaries,
  setSummaries,
}: UserDetailsActivitySummariesProps) {
  useEffect(() => {
    UserService.getUserActivitySummaries({ userId }).then((summaries) => {
      setSummaries(summaries);
    });
    // eslint-disable-next-line
  }, [userId]);

  return (
    <SummariesList
      userId={userId}
      summaries={summaries}
      setAddActivityModalOpen={setAddActivityModalOpen}
      addActivityModalOpen={addActivityModalOpen}
    />
  );
}
