import { useState } from 'react';
import {
  Activity as ActivityRAF,
  Avatar,
  CommentField,
  CommentList,
  LoadMorePaginator,
} from 'react-activity-feed'; // @ts-ignore
import {
  LoadMoreButton,
  LoadMoreButtonProps,
} from 'react-activity-feed/dist/components/LoadMoreButton';
import { NavLink as NavLinkRRD, useHistory } from 'react-router-dom';
import { Button, Col, Container, NavLink, Row } from 'reactstrap';
import SocialService from 'services/SocialService';

const SocialActivity = (props: any) => {
  const history = useHistory();
  const [deleted, setDeleted] = useState(false);

  const images = props.activity?.images?.map((i: string) => {
    return <img key={i} alt="" src={i} style={{ width: '100%' }} />;
  });

  const deletePost = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Are you sure you want to delete this post?');

    if (confirmed) {
      SocialService.deleteFeedPost(props.activity.id, props.activity.actor.id, 'user');
      setDeleted(true);
    }
  };

  if (deleted) return;

  return (
    <ActivityRAF
      activity={props.activity}
      Header={() => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            onClick={(e) => {
              history.push(`/admin/users/${props.activity.actor.id}`);
            }}
            style={{
              padding: 10,
              backgroundColor: '#FFFFFF',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Avatar size={40} image={props.activity.actor.data.profileImage} circle={true} />
            <span style={{ marginLeft: 10 }}>
              {props.activity.actor.data.firstName} {props.activity.actor.data.lastName}
            </span>
          </div>
          <div style={{ flex: 1 }} />
          <div style={{ padding: 10 }}>
            <Button color="link" onClick={() => deletePost()}>
              Delete
            </Button>
          </div>
        </div>
      )}
      Content={() => (
        <div style={{ backgroundColor: '#FFFFFF', width: '100%', position: 'relative' }}>
          <span style={{ padding: 10 }}>{props.activity.description}</span>
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                position: 'absolute',
                background: '#00000055',
                left: 0,
                right: 0,
                padding: 20,
              }}
            >
              <Container style={{ textAlign: 'center', color: 'white' }}>
                <Row>
                  <Col>
                    <span style={{ fontWeight: 'bold', fontSize: '20pt' }}>
                      {props.activity.distance / 1000}
                    </span>
                    <br />
                    km
                  </Col>
                  <Col>
                    <span style={{ fontWeight: 'bold', fontSize: '20pt' }}>
                      {(props.activity.duration / 60).toFixed(0)}
                    </span>
                    <br />
                    min
                  </Col>
                </Row>
                <Row>
                  <div
                    style={{
                      textAlign: 'center',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: 0,
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    <NavLink
                      to={`activity/${props.activity.foreign_id}`}
                      tag={NavLinkRRD}
                      onClick={() => {}}
                      activeClassName="active"
                    >
                      View Activity
                    </NavLink>
                  </div>
                </Row>
              </Container>
            </div>
            <div style={{ display: 'flex', overflowY: 'scroll' }}>{images}</div>
          </div>
        </div>
      )}
      Footer={() => (
        <div
          style={{
            padding: 10,
            backgroundColor: '#FFFFFF',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <Container>
            <Row style={{ textAlign: 'center' }}>
              <Col>
                <span>Likes: {props.activity.reaction_counts?.like ?? 0}</span>
              </Col>
              <Col>
                <span>Comments: {props.activity.reaction_counts?.comment ?? 0}</span>
              </Col>
            </Row>
          </Container>
          <br />
          <CommentList
            activityId={props.activity.id}
            Paginator={(p) =>
              LoadMorePaginator({
                ...p,
                LoadMoreButton: (l) =>
                  LoadMoreButton({
                    ...l,
                    children: LoadMoreButtonChildren(l, props),
                  }),
              })
            }
          />
          <CommentField activity={props.activity} />
        </div>
      )}
    />
  );
};

const LoadMoreButtonChildren = (l: LoadMoreButtonProps, props: any) => {
  l?.onClick();

  return 'Load More';
};

export default SocialActivity;
