import moment, { utc } from 'moment';

export function dateFormatter(date: string | Date, formatType: string) {
  return moment(date).format(formatType);
}

export function secondsFormatter(seconds: number, formatType: string) {
  return utc(seconds * 1000).format(formatType);
}

export function convertTimeToISOString(time: string) {
  return moment(time).toLocaleString();
}
