import { Config } from 'config';
import { useEffect, useState } from 'react';
import { FlatFeed, StreamApp } from 'react-activity-feed';
import SocialService from 'services/SocialService';
import SocialActivity from './SocialActivity';

export interface Props {
  streamFeedGroup: string;
  streamFeedUserId: string;
  displayNewPostUI?: boolean;
}

const SocialFeed = ({ streamFeedGroup, streamFeedUserId, displayNewPostUI = false }: Props) => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    SocialService.getSocialSessionToken().then((t) => setToken(t.sessionToken));
  }, []);

  return (
    <>
      {token && (
        <StreamApp apiKey={Config.streamApiKey} appId={Config.streamAppId} token={token}>
          {/* {displayNewPostUI && (<StatusUpdateForm feedGroup={streamFeedGroup} userId={streamFeedUserId} />)} */}
          <FlatFeed
            feedGroup={streamFeedGroup}
            userId={streamFeedUserId}
            notify
            Activity={SocialActivity}
            Placeholder={<div style={{ margin: '10px', textAlign: 'center' }}>No posts found</div>}
          />
        </StreamApp>
      )}
    </>
  );
};

export default SocialFeed;
