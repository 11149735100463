import Header from 'components/Headers/Header';
import { TicketTable } from 'components/Tables/TicketTable';

export function Tickets() {
  return (
    <>
      <Header />
      <TicketTable />
    </>
  );
}
