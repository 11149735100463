/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Header from 'components/Headers/Header';
import { ActivityModal } from 'components/Modals/ActivityModal';
import { User } from 'model/User';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { ActivityDetailsState } from 'recoil-state/activityState';
import ActivityService from 'services/ActivityService';
import UserService from 'services/UserService';
import { getPointsCounts } from '../ActivitiesState';
import { ActivityAwardPoints } from './ActivityAwardPoints';
import { ActivityDetailsCard } from './ActivityDetailsCard';
import { ActivityMap } from './ActivityMap';
// mapTypeId={google.maps.MapTypeId.ROADMAP}

interface Params {
  id: string;
}

export interface PointCountsType {
  totalPointsCounts: number;
  distanceTriggerPointsCounts: number;
  checkPointCollectionCounts: number;
}

export function ActivityDetails() {
  const [modalOpen, setModalOpen] = useState(false);
  const [pointCounts, setPointCounts] = useState<PointCountsType | undefined>();
  const { id: activityId } = useParams<Params>();
  const [activityDetails, setActivityDetails] = useRecoilState(ActivityDetailsState);

  const [userProfile, setUserProfile] = useState<User | null>();

  const getData = useCallback(async () => {
    const activityData = await ActivityService.getActivityData(activityId);
    if (activityData) {
      const userProfile = await UserService.getUserProfile({
        userId: activityData?.userId,
      });
      await setActivityDetails(activityData); // set Acitivity Details data into Recoil state
      setUserProfile(userProfile);
    }
  }, [activityId, setActivityDetails]);

  useEffect(() => {
    setPointCounts(getPointsCounts(activityDetails.awardPointEvents));
  }, [activityDetails]);

  useEffect(() => {
    getData();
  }, [activityId, getData]);

  return (
    <>
      <ActivityModal isOpen={modalOpen} toggleModal={setModalOpen} />
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <ActivityMap activity={activityDetails} />
          </Col>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <ActivityDetailsCard
              activity={activityDetails}
              userProfile={userProfile}
              activityId={activityId}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              pointCounts={pointCounts}
              userId={activityDetails?.userId}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <ActivityAwardPoints awardPointEvents={activityDetails.awardPointEvents} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
