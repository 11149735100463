import { Config } from 'config';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

class Firebase {
  app: FirebaseApp;
  auth: Auth;

  constructor() {
    this.app = initializeApp(Config.firbaseConfig);
    this.auth = getAuth(this.app);
  }

  doSignOut = () => this.auth.signOut();
}

export default new Firebase();
