import { Team } from 'model/Team';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

export function TeamDetailsSummary({ team }: { team: Team }) {
  return (
    <Card className="card-profile shadow">
      <Row className="justify-content-center">
        <Col className="order-lg-2" lg="3">
          <div className="card-profile-image">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img alt="..." className="rounded-circle" src={team?.profileImage} />
            </a>
          </div>
        </Col>
      </Row>
      <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
      <CardBody className="pt-0 pt-md-4">
        <div className="mt-6 text-center">
          <div className="mt-6">
            <h3>Team Name: {`${team?.title}`}</h3>
          </div>

          <div className="mt-1 mb-4">
            <h3>Team ID: {`${team?.teamId}`}</h3>
          </div>

          <div className="h5">
            <i className="ni business_briefcase-24 mr-2" />
            Activities: {team?.totalActivities}
          </div>

          <div className="h5">
            <i className="ni business_briefcase-24 mr-2" />
            Points: {team?.totalPoints}
          </div>

          <div className="h5">
            <i className="ni shoe mr-2" />
            Distance: {team?.totalDistance}
          </div>

          <div className="h5">
            <i className="ni location_pin mr-2" />
            Steps: {team?.totalSteps}
          </div>

          <div className="h5">
            <i className="ni business_briefcase-24 mr-2" />
            Checkpoints: {team?.totalCheckpoints} ({team?.totalUniqueCheckpoints} unique)
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
