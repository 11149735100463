// reactstrap components
import { Col, Container, Modal, Row } from 'reactstrap';

export function AuthenticationModal({ isOpen, toggleModal, userId }) {
  return (
    <Container>
      <Row>
        <Col md="4">
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your attention is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(!isOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i className="ni ni-bell-55 ni-3x" />
                <p>
                  Your login details are not authorised. Please contact explore@districtrace.com for
                  access.
                </p>
                <p>UserId: {userId}</p>
              </div>
            </div>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}
