import { Team } from 'model/Team';
import { User } from 'model/User';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Button, CardBody, CardHeader, Col, Media, Row, Table } from 'reactstrap';
import TeamService from 'services/TeamService';

interface TeamDetailsUsersPropsType {
  teamUsers: User[];
  teamId: string;
  teamTitle?: string;
  setTeam: (team: Team) => void;
}

function TeamUsersList({ teamUsers, teamId, setTeam }: TeamDetailsUsersPropsType) {
  const removeUser = (e, userId) => {
    e.stopPropagation();
    TeamService.removeUserFromTeam({ userId, teamId }).then((team) => {
      // setTeam(team);    // TODO: Response doesn't include team info correctly.

      /**get team info and set it again after removing a user from team.
       * we may use the response above once the response is fixed correctly.
       */
      TeamService.getTeamById({ teamId }).then((response) => {
        setTeam(response);
      });
    });
  };
  const history = useHistory();
  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Image</th>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Email</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {teamUsers?.map((res, idx) => (
          <tr
            key={idx}
            onClick={(e) => history.push(`/admin/users/${res.userId}`)}
            style={{ cursor: 'pointer' }}
          >
            <th scope="row">
              <Media className="align-items-center">
                <img className="avatar rounded-circle mr-3" alt="..." src={res.profileImage} />
              </Media>
            </th>
            <td>{res.firstName}</td>
            <td>{res.lastName}</td>
            <td>{res.email}</td>
            <td>
              <Button color="danger" onClick={(e) => removeUser(e, res.userId)} size="sm">
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export function TeamDetailsUsers({
  teamUsers,
  teamId,
  teamTitle,
  setTeam,
}: TeamDetailsUsersPropsType) {
  const [csvData, setCsvData] = useState([]);
  useEffect(() => {
    setCsvData(
      teamUsers.map((user) => ({
        teamId: teamId,
        teamName: teamTitle,
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      })),
    );
  }, [teamUsers, teamId, teamTitle]);
  return (
    <>
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8"></Col>
          <Col className="text-right" xs="4">
            <CSVLink data={csvData}>Export CSV</CSVLink>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <TeamUsersList teamUsers={teamUsers} teamId={teamId} setTeam={setTeam} />
      </CardBody>
    </>
  );
}
