import { Team } from 'model/Team';
import { mixed, object, string } from 'yup';

export const defaults: Team = {
  teamId: '',
  title: '',
  profileImage: '',
  missionId: '',
  captainUserId: '',
  teamChatId: '',
  externalTeamSlug: '',
  externalTeamId: '',
  users: [],
  distanceTarget: 0,
  stepsTarget: 0,
  dailyStepsTarget: 0,
  donationTarget: 0,
  totalMembers: 0,
  totalPoints: 0,
  totalActivities: 0,
  totalActivityDuration: 0,
  totalChallenges: 0,
  totalDistance: 0,
  totalSteps: 0,
  totalCheckpoints: 0,
  totalDonations: 0,
  totalUniqueCheckpoints: 0,
};

export function getSchema() {
  const title = string()
    .trim()
    .min(2, 'At least 2 characters')
    .max(40, 'At most 40 characters')
    .required('Team Name is required.');
  const profileImage = mixed().notRequired();

  return object().shape({
    title,
    profileImage,
  });
}

export function getAddUserSchema() {
  const userId = string().trim().required('User Id is required.');

  return object().shape({
    userId,
  });
}
