import { useEffect, useState } from 'react';
import { NavLink as NavLinkRRD, useHistory } from 'react-router-dom';
import { Media, NavLink, Table } from 'reactstrap';
import UserService, { UserTeam } from 'services/UserService';

export function UserDetailsTeams({ userId }) {
  const [teams, setTeams] = useState<UserTeam[]>([]);
  const history = useHistory();

  useEffect(() => {
    UserService.getUserTeams({ userId }).then((teams) => {
      setTeams(teams);
    });
  }, [userId]);
  // TODO: User Teams;
  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col" />
          <th scope="col">Team ID</th>
          <th scope="col">Title</th>
          <th scope="col">Mission ID</th>
        </tr>
      </thead>
      <tbody>
        {teams.map((team, idx) => (
          <tr
            key={idx}
            onClick={(e) => history.push(`/admin/teams/${team.teamId}`)}
            style={{ cursor: 'pointer' }}
          >
            <th scope="row">
              <Media className="align-items-center">
                <a className="avatar rounded-circle mr-3" href="#pablo">
                  <img alt="..." src={team.profileImage} />
                </a>
              </Media>
            </th>
            <td>
              <NavLink
                to={`teams/${team.teamId}`}
                tag={NavLinkRRD}
                onClick={() => {}}
                activeClassName="active"
                styles={{ paddingLeft: 0 }}
              >
                {team.teamId}
              </NavLink>
            </td>
            <td>{team.title}</td>
            <td>{team.missionId}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
