import { PaginationQueryParams } from 'model/PaginationQueryParams';
import { MissionTicket, MissionTicketRedemption } from 'model/Ticket';
import Service from './index';

export interface SearchTicketParams {
  query: string;
}

export interface TicketSearchResult {
  userId: string;
  firstName: string;
  lastName: string;
  profileImage: string;
}

export interface MissionTicketsResponse {
  missionTicketRedemptions: MissionTicketRedemption[];
  missionTickets: MissionTicket[];
}

interface RedeemTicketParams {
  ticketCode: string;
  missionId: string;
  userId: string;
}

async function getMissionTickets(params?: PaginationQueryParams) {
  try {
    const response = await Service.apiRequest.get<MissionTicketsResponse>(`missiontickets/all`, {
      params,
    });

    return response.data;
  } catch (e) {
    return null;
  }
}

async function getTicketById(params: { ticketCode: string }) {
  try {
    const response = await Service.apiRequest.get<MissionTicket>(
      `missiontickets/${params.ticketCode}`,
    );

    return response.data;
  } catch (e) {
    return null;
  }
}

async function searchTickets(params: SearchTicketParams) {
  try {
    const response = await Service.apiRequest.get<MissionTicket[]>(`missiontickets/search/`, {
      params,
    });

    return response.data;
  } catch (e) {
    return [];
  }
}

async function redeemTicket(params: RedeemTicketParams) {
  try {
    const response = await Service.apiRequest.put<MissionTicket>(
      `missiontickets/${params.ticketCode}/redeem?missionId=${params.missionId}&userid=${params.userId}`,
      params,
    );
    return response.data;
  } catch (e) {
    return null;
  }
}

async function updateTicketCategory(
  ticketCode: string,
  ticketCategory: string,
  missionId?: string,
) {
  try {
    const endpoint = `MissionTickets/${ticketCode}/updateTicketCategory`;

    const queryParams = new URLSearchParams({
      ticketCategory,
    });

    if (missionId) {
      queryParams.append('missionId', missionId);
    }

    const response = await Service.apiRequest.post(endpoint + `?${queryParams.toString()}`);

    return response.data;
  } catch (e) {
    return null;
  }
}

export default {
  getMissionTickets,
  getTicketById,
  searchTickets,
  redeemTicket,
  updateTicketCategory,
};
