export enum AppEnv {
  DEV = 'development',
  PROD = 'production',
  TEST = 'test',
}

const env = process.env.NODE_ENV;
const isProd = env === 'production';
const appName = process.env.REACT_APP_APP_NAME;
const logo = process.env.REACT_APP_LOGO_URL;
const apiBaseUrl = isProd
  ? process.env.REACT_APP_API_BASE_PROD_URL
  : process.env.REACT_APP_API_BASE_DEV_URL;
const activityType = process.env.REACT_APP_DISPLAY_ACTIVITY_TYPE;
const displayCustomActivity = process.env.REACT_APP_DISPLAY_CUSTOM_ACTIVITY;
const displaySelectedResult = process.env.REACT_APP_DISPLAY_SELECTED_RESULT;
const streamAppId = process.env.REACT_APP_STREAM_APP_ID;
const streamApiKey = process.env.REACT_APP_STREAM_API_KEY;
const streamFeedGroup = process.env.REACT_APP_STREAM_FEED_GROUP;
const streamFeedUserId = process.env.REACT_APP_STREAM_FEED_USER_ID;

const contentfulSpaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const contentfulAccessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const firbaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

const googleMapConfig = {
  scrollwheel: false,
  styles: [
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [{ visibility: 'off' }],
    },
    { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [{ color: '#c2f7a3' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#cfcfcf' }],
    },
    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    { featureType: 'water', stylers: [{ color: '#a1e2fb' }] },
  ],
};

export const Config = {
  appName,
  logo,
  apiBaseUrl,
  firbaseConfig,
  googleMapConfig,
  activityType,
  displayCustomActivity,
  displaySelectedResult,
  streamAppId,
  streamApiKey,
  streamFeedGroup,
  streamFeedUserId,
  contentfulSpaceId,
  contentfulAccessToken,
};
