import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { useMissions } from 'services/ContentfulService';

interface Props {
  missions: string[];
}

export function UserDetailsJoinedMissions({ missions }: Props) {
  const missionMetadata = useMissions();
  const history = useHistory();
  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Mission</th>
        </tr>
      </thead>
      <tbody>
        {missions?.map((missionId, idx) => (
          <tr
            key={idx}
            onClick={(e) => history.push(`/admin/tickets/${missionId}`)}
            style={{ cursor: 'pointer' }}
          >
            <td>{missionMetadata[missionId]?.name ?? missionId}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
