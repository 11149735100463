import { Config } from 'config';
import { countries } from 'countries-list';
import { CheckBoxInput } from 'elements/CheckBoxInput';
import { DateTimeInput } from 'elements/DateTimeInput';
import { SelectInput } from 'elements/SelectInput';
import { TextInput } from 'elements/TextInput';
import { connect, Formik } from 'formik';
import Activity from 'model/Activity';
import { User } from 'model/User';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import ActivityService from 'services/ActivityService';
import UserService, { ActivitySummaries } from 'services/UserService';
import { ActivitySport, ActivityType } from 'utils/converters';
import { v4 as uuidv4 } from 'uuid';
import { getSchema } from '../ActivitiesState';

const countryOptions = Object.keys(countries)
  .map((country) => {
    const name = countries[country].name;
    return { label: name, value: country.toLowerCase() };
  })
  .sort((a, b) => {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const HandleFieldChange = connect(({ formik }: any) => {
  useEffect(() => {
    formik.setFieldValue(
      'endTime',
      moment(formik.values.startTime).add(formik.values.duration, 'seconds').toDate(),
    );
    // eslint-disable-next-line
  }, [formik.values.duration, formik.values.startTime]);
  return null;
});

export interface ActivityDetailsAddFormProps {
  activity: Activity;
  activityId: string;
  setActivity: (activity: Activity) => void;
}

interface Props {
  isModalOpen: boolean;
  toggleModal: (status: boolean) => void;
  userId: string;
  setUser: (user: User) => void;
  summaries?: ActivitySummaries[];
  setSummaries?: (summaries) => void;
}

export function ActivityDetailsAddForm({
  isModalOpen,
  toggleModal,
  userId,
  setUser,
  summaries,
  setSummaries,
}: Props) {
  const activityId = uuidv4();
  const awardPointEventId = uuidv4();
  const [visitorCountryCode, setVisitorCountryCode] = useState('my');
  useEffect(() => {
    async function fetchCountryInfo() {
      try {
        const response = await (await fetch('https://extreme-ip-lookup.com/json/')).json();
        setVisitorCountryCode(response?.countryCode.toLowerCase());
      } catch (error) {
        // console.error(error);
      }
    }
    fetchCountryInfo();
  }, [visitorCountryCode]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        startTime: new Date(),
        endTime: new Date(),
        distance: 0,
        points: 0,
        duration: 0,
        disqualified: false,
        country: visitorCountryCode,
        activityType: 0,
        activitySport: 0,
        selectedResult: false,
      }}
      validationSchema={getSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values) => {
        ActivityService.addActivityDetails({
          userId,
          activityId,
          disqualified: values.disqualified,
          startTime: values.startTime,
          endTime: values.endTime,
          distance: values.distance,
          points: values.points,
          locationStamps: [],
          countryCode: values.country,
          activityType: parseInt(`${values.activityType}`, 10),
          activitySport: parseInt(`${values.activitySport}`, 10),
          selectedResult: values.selectedResult,
          awardPointEvents: [
            {
              awardPointEventId,
              checkpointCollectionEventId: null,
              userId,
              awardPointTriggerType: 4,
              points: values.points,
              activeMultiplier: 1,
              activityId,
              eventTimestamp: values.endTime,
            },
          ],
        })
          .then((activity) => {
            toggleModal(!isModalOpen);
            UserService.getUserActivitySummaries({ userId }).then((summaries) => {
              setSummaries(summaries);
            });
          })
          .catch((err) => {});
      }}
    >
      {({ submitForm, values, handleChange }) => {
        return (
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <div>
                    <HandleFieldChange />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div>
                    <DateTimeInput
                      className="form-control-alternative"
                      name="startTime"
                      label="Start Time"
                      value={values.startTime}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div>
                    <TextInput
                      id="input-duration"
                      className="form-control-alternative"
                      name="duration"
                      label="Duration"
                      placeholder="Duration"
                      type="number"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div>
                    <DateTimeInput
                      className="form-control-alternative"
                      name="endTime"
                      label="End Time"
                      dependent
                      value={values.endTime}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <TextInput
                    className="form-control-alternative"
                    id="input-distance"
                    name="distance"
                    label="Distance"
                    placeholder="Distance"
                    type="number"
                  />
                </Col>
                <Col lg="6">
                  <TextInput
                    className="form-control-alternative"
                    id="input-points"
                    name="points"
                    label="Points"
                    placeholder="Points"
                    type="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <SelectInput
                    className="form-control form-control-alternative"
                    id="input-country"
                    name="country"
                    label="Country"
                    type="select"
                    options={countryOptions}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <CheckBoxInput
                    className="form-control-alternative"
                    id="input-disqualified"
                    name="disqualified"
                    label="Disqualified"
                    placeholder="disqualified"
                  />
                </Col>
              </Row>
              {Config.activityType === 'TRUE' && (
                <Row>
                  <Col lg="6">
                    <SelectInput
                      className="form-control-alternative"
                      id="input-activity-type"
                      name="activityType"
                      label="Activity Type"
                      type="select"
                      options={[
                        { label: 'Explore', value: ActivityType.Explore },
                        { label: 'Run Challenge', value: ActivityType.RunChallenge },
                        { label: 'Free Run', value: ActivityType.FreeRun },
                        {
                          label: 'External Free Run',
                          value: ActivityType.ExternalFreeRun,
                        },
                      ]}
                    />
                  </Col>
                  <Col lg="6">
                    <SelectInput
                      className="form-control-alternative"
                      id="input-activity-sport"
                      name="activitySport"
                      label="Activity Sport"
                      type="select"
                      options={[
                        { label: 'On Foot', value: ActivitySport.OnFoot },
                        { label: 'Cycling', value: ActivitySport.Cycling },
                      ]}
                    />
                  </Col>
                </Row>
              )}
              {Config.displaySelectedResult === 'TRUE' && (
                <Row>
                  <Col lg="12">
                    <CheckBoxInput
                      className="form-control-alternative"
                      id="input-selectedResult"
                      name="selectedResult"
                      label="Submitted Result"
                      placeholder="selectedResult"
                    />
                  </Col>
                </Row>
              )}
            </div>
            <div className="text-center">
              <Button className="my-4" onClick={submitForm} color="primary" type="button">
                Add
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
