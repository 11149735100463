import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingSpinnerTableRow } from 'elements/LoadingSpinnerTableRow';
import PaginationRow from 'elements/PaginationRow';
import { SearchInput } from 'elements/SearchInput';
import { Card, CardHeader, Col, Container, Media, Row, Table } from 'reactstrap';
import UserService from 'services/UserService';
import { displayGender } from 'utils/converters';
import { PAGE_SIZE } from 'variables/constants';

export function UserTable() {
  const history = useHistory();
  const [searchText, setSearchText] = useState<string>();
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getUsers = useCallback(() => {
    /**
     * We disable getAllUsers in the initial loading because it's affecting the performance
     * Will consider again when we implement the pagination for this page.
     */
    setLoading(true);
    UserService.getAllUsers({ page, pageSize: PAGE_SIZE }).then((response) => {
      setLoading(false);
      setSearchResults(response.slice(0, 50));
    });
  }, [page]);

  const onSearch = () => {
    setLoading(true);
    UserService.searchUsers({ query: searchText }).then((response) => {
      setLoading(false);
      setSearchResults(response);
    });
  };

  const setNextPage = () => {
    if (searchResults.length === PAGE_SIZE) {
      setPage(page + 1);
    }
  };

  const setPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (page > 0) {
      getUsers();
    }
  }, [getUsers, page]);

  return (
    <>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Users</h3>
                  </Col>
                  <Col lg="6" xl="3">
                    <SearchInput onChange={setSearchText} onSearch={onSearch} value={searchText} />
                  </Col>
                </Row>
                {!searchText && (
                  <PaginationRow
                    page={page}
                    isFirstPage={page === 1}
                    isLastPage={searchResults.length < PAGE_SIZE}
                    setPreviousPage={setPreviousPage}
                    setNextPage={setNextPage}
                  />
                )}
              </CardHeader>
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" />
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Email Address</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {loading && <LoadingSpinnerTableRow />}
                  {searchResults.map((res, idx) => {
                    let userInfo = res;
                    /**The below is caused by the different format of response from `/search` and `/users`
                     * We should need to modify the backend response format in the future.
                     */
                    if (res.user) userInfo = res.user;
                    return (
                      <tr
                        key={idx}
                        onClick={(e) => {
                          history.push(`/admin/users/${userInfo.userId}`);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <th scope="row">
                          <Media className="align-items-center">
                            <a className="avatar rounded-circle mr-3" href="#pablo">
                              <img alt="..." src={userInfo?.profileImage} />
                            </a>
                          </Media>
                        </th>
                        <td>{userInfo?.firstName}</td>
                        <td>{userInfo?.lastName}</td>
                        <td>{displayGender(userInfo?.gender)}</td>
                        <td>{userInfo?.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardHeader>
                {!searchText && (
                  <PaginationRow
                    page={page}
                    isFirstPage={page === 1}
                    isLastPage={searchResults.length < PAGE_SIZE}
                    setPreviousPage={setPreviousPage}
                    setNextPage={setNextPage}
                  />
                )}
              </CardHeader>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
