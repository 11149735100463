import { ActivityDetailsAddForm } from 'layouts/Activities/ActivityDetails/ActivityDetailsAddForm';
import { Card, CardBody, CardHeader, Modal } from 'reactstrap';

export function AddActivityModal({
  isOpen,
  toggleModal,
  userId,
  setUser,
  summaries,
  setSummaries,
}) {
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={() => toggleModal(!isOpen)}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <strong>Add Activity</strong>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <ActivityDetailsAddForm
              isModalOpen={isOpen}
              toggleModal={toggleModal}
              userId={userId}
              setUser={setUser}
              summaries={summaries}
              setSummaries={setSummaries}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
