import classnames from 'classnames';
import SocialFeed from 'components/Social/SocialFeed';
import { User, WearableProviderAccount } from 'model/User';
import React, { useCallback, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { ActivitySummaries } from 'services/UserService';
import { UserAppFeedback } from './UserAppFeedback';
import { UserDetailsActivitySummaries } from './UserDetailsActivitySummaries';
import { UserDetailsForm } from './UserDetailsForm';
import { UserDetailsJoinedMissions } from './UserDetailsJoinedMissions';
import { UserDetailsMissionTickets } from './UserDetailsMissionTickets';
import { UserDetailsTeams } from './UserDetailsTeams';
import { UserWearableProviders } from './UserWearableProviders';

export interface UserTabsProps {
  user: User;
  userId: string;
  setUser: (user: User) => void;
  redemptionModalOpen: boolean;
  setRedemptionModalOpen: (modalOpen) => void;
  addActivityModalOpen: boolean;
  setAddActivityModalOpen: (modalOpen) => void;
  summaries?: ActivitySummaries[];
  setSummaries?: (summaries) => void;
  wearableProviders: WearableProviderAccount[];
}

const tabsList = [
  { tabId: '1', tabName: 'Details' },
  { tabId: '8', tabName: 'Feed' },
  { tabId: '2', tabName: 'Activities' },
  { tabId: '3', tabName: 'Teams' },
  { tabId: '4', tabName: 'Mission Tickets' },
  { tabId: '5', tabName: 'Joined Missions' },
  { tabId: '6', tabName: 'Linked Wearables' },
  { tabId: '7', tabName: 'App Feedback' },
];

function TabNav({ toggle, activeTab }) {
  return (
    <Nav tabs>
      {tabsList.map((tab, idx) => (
        <NavItem key={idx}>
          <NavLink
            className={classnames({ active: activeTab === tab.tabId })}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              toggle(`${tab.tabId}`);
            }}
          >
            {tab.tabName}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}

function TabPaneWrapper({ tabId, children }: { tabId: string; children: React.ReactNode }) {
  return (
    <TabPane tabId={tabId}>
      <Row>
        <Col sm="12">{children}</Col>
      </Row>
    </TabPane>
  );
}

export function UserTabs({
  user,
  userId,
  setUser,
  redemptionModalOpen,
  setRedemptionModalOpen,
  addActivityModalOpen,
  setAddActivityModalOpen,
  summaries,
  setSummaries,
  wearableProviders,
}: UserTabsProps) {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab],
  );

  return (
    <div>
      <TabNav toggle={toggle} activeTab={activeTab} />
      <TabContent activeTab={activeTab}>
        <TabPaneWrapper tabId="1">
          <UserDetailsForm user={user} userId={userId} setUser={setUser} />
        </TabPaneWrapper>
        <TabPaneWrapper tabId="8">
          <div style={{ width: '100%', overflowY: 'scroll', height: '600px' }}>
            <div style={{ width: '600px', margin: '0 auto' }}>
              <SocialFeed streamFeedGroup={'user'} streamFeedUserId={userId} />
            </div>
          </div>
        </TabPaneWrapper>
        <TabPaneWrapper tabId="2">
          <UserDetailsActivitySummaries
            userId={userId}
            addActivityModalOpen={addActivityModalOpen}
            setAddActivityModalOpen={setAddActivityModalOpen}
            summaries={summaries}
            setSummaries={setSummaries}
          />
        </TabPaneWrapper>
        <TabPaneWrapper tabId="3">
          <UserDetailsTeams userId={userId} />
        </TabPaneWrapper>
        <TabPaneWrapper tabId="4">
          <UserDetailsMissionTickets
            tickets={user.missionTickets}
            userId={userId}
            isModalOpen={redemptionModalOpen}
            setModalOpen={setRedemptionModalOpen}
          />
        </TabPaneWrapper>
        <TabPaneWrapper tabId="5">
          <UserDetailsJoinedMissions missions={user.joinedMissions} />
        </TabPaneWrapper>
        <TabPaneWrapper tabId="6">
          <UserWearableProviders wearableProviders={wearableProviders} />
        </TabPaneWrapper>
        <TabPaneWrapper tabId="7">
          <UserAppFeedback />
        </TabPaneWrapper>
      </TabContent>
    </div>
  );
}
