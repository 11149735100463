import Header from 'components/Headers/Header';
import { MissionTicket } from 'model/Ticket';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import TicketService from 'services/TicketService';
import { TicketDetailsSummary } from './TicketDetailsSummary';
import { TicketTabs } from './TicketTabs';

interface Params {
  ticketCode: string;
}

export function TicketDetails() {
  const { ticketCode } = useParams<Params>();
  const [ticket, setTicket] = useState<MissionTicket>();

  useEffect(() => {
    TicketService.getTicketById({ ticketCode }).then((response) => {
      setTicket(response);
    });
  }, [ticketCode]);
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <TicketDetailsSummary ticket={ticket} />
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <TicketTabs ticket={ticket} ticketCode={ticketCode} setTicket={setTicket} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
