import { Input } from 'reactstrap';
import { useMissions } from 'services/ContentfulService';

export interface Props {
  onMissionUpdate: (missionId: string) => void;
  displayLabel?: boolean;
  defaultValueLabel?: string;
}

export function MissionSelector({ onMissionUpdate, displayLabel, defaultValueLabel }: Props) {
  const missions = useMissions();

  const missionOptions = [
    {
      label: defaultValueLabel || 'All Missions',
      value: '',
    },
    ...Object.entries(missions).map((e) => {
      return {
        //@ts-ignore
        label: e[1].name,
        value: e[0],
      };
    }),
  ];

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {displayLabel && <span style={{ paddingRight: 20 }}>Mission: </span>}
      <Input
        className={'form-control form-control-alternative'}
        id={'input-mission'}
        type={'select'}
        onChange={(e) => onMissionUpdate(e.target.value)}
      >
        <>
          {missionOptions?.map((opt, idx) => (
            <option key={idx} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </>
      </Input>
    </div>
  );
}
