import { SelectInput } from 'elements/SelectInput';
import { TextInput } from 'elements/TextInput';
import { Formik } from 'formik';
import { MissionTicket } from 'model/Ticket';
import { useState } from 'react';
import { Button, CardBody, CardHeader, Col, Form, Row, Spinner } from 'reactstrap';
import { useMissions, useTicketCategories } from 'services/ContentfulService';
import TicketService from 'services/TicketService';

export interface TicketDetailsFormProps {
  ticket: MissionTicket;
  ticketCode: string;
  setTicket: (ticket: MissionTicket) => void;
}

export function TicketDetailsForm({ ticket, ticketCode, setTicket }: TicketDetailsFormProps) {
  const [loading, setLoading] = useState(false);

  const missions = useMissions();

  const isTicketCategoryRegistrationEnabled =
    missions[ticket?.missionId]?.ticketingConfiguration.fields.isTicketCategoryRegistrationEnabled;

  const ticketCategories = useTicketCategories().filter(
    (item) => item.isTeamCategory === !!ticket?.teamId,
  );

  const ticketCategoryOptions = [
    ...ticketCategories.map((ticketCategory) => {
      return {
        //@ts-ignore
        label: `${ticketCategory.isTeamCategory ? 'Team' : 'Individual'} ${
          ticketCategory.leaderboardLabel
        }`,
        value: ticketCategory.distance,
      };
    }),
  ];

  return (
    <>
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}
        >
          <Spinner color="primary" children={false} />
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...ticket,
          }}
          validateOnChange
          validateOnBlur
          onSubmit={(values) => {
            setLoading(true);

            TicketService.updateTicketCategory(ticketCode, values.ticketCategory, ticket.missionId)
              .then(() => {
                TicketService.getTicketById({ ticketCode }).then((ticket) => {
                  setTicket(ticket);
                  setLoading(false);
                });
              })
              .catch((err) => {
                setLoading(false);
              });
          }}
        >
          {({ dirty, submitForm }) => {
            return (
              <>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    <Col className="text-right" xs="4">
                      {dirty && (
                        <Button color="primary" onClick={submitForm} size="sm">
                          Save
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">Team information</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-redemption-limit"
                            name="redemptionLimit"
                            label="Redemption Limit"
                            placeholder="Redemption Limit"
                            readOnly
                          />
                        </Col>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-mission-id"
                            name="missionId"
                            label="Mission ID"
                            placeholder="Mission ID"
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-team-id"
                            name="teamId"
                            label="Team ID"
                            placeholder="Team ID"
                            readOnly
                          />
                        </Col>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-captain-ticket"
                            name="captainTicket"
                            label="Captain Ticket"
                            placeholder="Captain Ticket"
                            readOnly
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">Ticket Registration Metadata</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-firstname"
                            name="firstname"
                            label="First Name"
                            placeholder="Redemption Limit"
                            readOnly
                          />
                        </Col>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-last-name"
                            name="lastname"
                            label="Last Name"
                            placeholder="Last Name"
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            readOnly
                          />
                        </Col>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-date-of-birth"
                            name="dateOfBirth"
                            label="Date of Birth"
                            placeholder="Date of Birth"
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-gender"
                            name="gender"
                            label="Gender"
                            placeholder="Gender"
                            readOnly
                          />
                        </Col>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-external-userid"
                            name="externalUserId"
                            label="External User ID"
                            placeholder="Gender"
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <TextInput
                            className="form-control-alternative"
                            id="input-external-username"
                            name="externalUsername"
                            label="External Username"
                            placeholder="External Username"
                            readOnly
                          />
                        </Col>
                        <Col lg="6">
                          {isTicketCategoryRegistrationEnabled ? (
                            <SelectInput
                              className="form-control form-control-alternative"
                              id="input-ticket-category"
                              name="ticketCategory"
                              label="Ticket Category"
                              type="select"
                              disabled={!!ticket?.teamId && !ticket.captainTicket}
                              options={ticketCategoryOptions}
                            />
                          ) : (
                            <TextInput
                              className="form-control-alternative"
                              id="input-ticket-category"
                              name="ticketCategory"
                              label="Ticket Category"
                              placeholder="Ticket Category"
                              readOnly
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
}
