import { CheckBoxInput } from 'elements/CheckBoxInput';
import { DateTimeInput } from 'elements/DateTimeInput';
import { TextInput } from 'elements/TextInput';
import { Formik } from 'formik';
import Activity from 'model/Activity';
import { Button, Col, Form, Row } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ActivityDetailsState } from 'recoil-state/activityState';
import ActivityService from 'services/ActivityService';
import { getSchema } from '../ActivitiesState';

export interface ActivityDetailsEditFormProps {
  activity: Activity;
  activityId: string;
  setActivity: (activity: Activity) => void;
}

interface Props {
  isModalOpen: boolean;
  toggleModal: (status: boolean) => void;
}

export function ActivityDetailsEditForm({ isModalOpen, toggleModal }: Props) {
  const activityDetails = useRecoilValue(ActivityDetailsState);
  const setActivityDetails = useSetRecoilState(ActivityDetailsState);
  const activityId = activityDetails.activityId;

  const initialDuration =
    (activityDetails.endTime.getTime() - activityDetails.startTime.getTime()) / 1000;

  const getInitialDurationString = (s: number) => {
    var n = s % (24 * 3600);
    var hours = Math.round(n / 3600);

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        startTime: activityDetails.startTime,
        endTime: activityDetails.endTime,
        duration: getInitialDurationString(initialDuration),
        distance: activityDetails.distance,
        points: activityDetails.points,
        disqualified: activityDetails.disqualified,
      }}
      validationSchema={getSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values) => {
        const elements = values.duration.split(':').map((n) => parseInt(n));
        const totalMs = elements[0] * 60 * 60 * 1000 + elements[1] * 60 * 1000 + elements[2] * 1000;

        ActivityService.editActivityDetails(
          { activityId },
          {
            disqualified: values.disqualified,
            startTime: values.startTime,
            endTime: new Date(values.startTime.getTime() + totalMs),
            distance: values.distance,
            points: values.points,
          },
        )
          .then((activity) => {
            toggleModal(!isModalOpen);
            setActivityDetails((details) => ({ ...details, ...activity }));
          })
          .catch((err) => {});
      }}
    >
      {({ submitForm, values, handleChange }) => {
        return (
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <div>
                    <DateTimeInput
                      className="form-control-alternative"
                      name="startTime"
                      label="Start Time"
                      value={values.startTime}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <TextInput
                    className="form-control-alternative"
                    id="input-duration"
                    name="duration"
                    label="Duration"
                    placeholder="hh:mm:ss"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <TextInput
                    className="form-control-alternative"
                    id="input-distance"
                    name="distance"
                    label="Distance"
                    placeholder="Distance"
                    type="number"
                  />
                </Col>
                <Col lg="6">
                  <TextInput
                    className="form-control-alternative"
                    id="input-points"
                    name="points"
                    label="Points"
                    placeholder="Points"
                    type="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <CheckBoxInput
                    className="form-control-alternative"
                    id="input-disqualified"
                    name="disqualified"
                    label="Disqualified"
                    placeholder="disqualified"
                  />
                </Col>
              </Row>
            </div>
            <div className="text-center">
              <Button className="my-4" onClick={submitForm} color="primary" type="button">
                Edit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
