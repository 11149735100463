import moment, { duration } from 'moment';
import { useCallback, useEffect, useState } from 'react';
// reactstrap components
import Header from 'components/Headers/Header';
import { RemoveActivityModal } from 'components/Modals/RemoveActivityModal';
import { Config } from 'config';
import { LoadingSpinnerTableRow } from 'elements/LoadingSpinnerTableRow';
import PaginationRow from 'elements/PaginationRow';
import Activity from 'model/Activity';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import {
  Card,
  CardFooter,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { useRecoilState } from 'recoil';
import { RemoveActivityModalState } from 'recoil-state/modalState';
import ActivityService from 'services/ActivityService';
import {
  getActivitySportLabel,
  getActivityTypeLabel,
  getDisqualifiedReasonLabel,
} from 'utils/converters';
import { PAGE_SIZE } from 'variables/constants';
import { ActivitiesHeader } from './ActivitiesHeader';

const Activities = () => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [minDistance, setMinDistance] = useState<number>(0);
  const [customActivityFilter, setCustomActivityFilter] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(moment(new Date()).subtract(7, 'd')['_d']);
  const [missionFilter, setMissionFilter] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<Date>(moment(new Date())['_d']);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [removeActivityModal, setRemoveActivityModal] = useRecoilState(RemoveActivityModalState);

  const getActivities = useCallback(() => {
    setLoading(true);
    ActivityService.getAllActivities({
      minDistance: minDistance,
      customActivities: customActivityFilter,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      missionId: missionFilter,
      page,
      pageSize: PAGE_SIZE,
    }).then((activities) => {
      setLoading(false);
      setActivities(activities);
    });
  }, [minDistance, customActivityFilter, startDate, endDate, missionFilter, page]);

  const setNextPage = () => {
    if (activities.length === PAGE_SIZE) {
      setPage(page + 1);
    }
  };

  const setPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleOnClickDeleteActivity = (e: React.MouseEvent, activityId: string, userId: string) => {
    e.preventDefault();

    setRemoveActivityModal({
      isModalOpen: !removeActivityModal.isModalOpen,
      params: { activityId, userId },
    });
  };

  useEffect(() => {
    if (page > 0) {
      getActivities();
    }
  }, [getActivities, page]);

  const activityRows = activities.map((a, idx) => {
    return (
      <tr key={idx}>
        <th scope="row">
          <NavLink
            to={`activity/${a.activityId}`}
            tag={NavLinkRRD}
            onClick={() => {}}
            activeClassName="active"
            styles={{ paddingLeft: 0 }}
          >
            {a.activityId}
          </NavLink>
        </th>
        <th>
          <NavLink
            to={`users/${a.userId}`}
            tag={NavLinkRRD}
            onClick={() => {}}
            activeClassName="active"
            styles={{ paddingLeft: 0 }}
          >
            {a.userId}
          </NavLink>
        </th>
        {Config.activityType === 'TRUE' && <td>{getActivityTypeLabel(a.activityType)}</td>}
        {Config.activityType === 'TRUE' && <td>{getActivitySportLabel(a.activitySport)}</td>}
        {Config.displayCustomActivity === 'TRUE' && <td>{a.customActivityType}</td>}
        <td>{a.distance}m</td>
        <td>{a.steps}</td>
        <td>{a.points}</td>
        <td>{a.disqualified ? 'Disqualified' : 'Qualified'}</td>
        <td>{getDisqualifiedReasonLabel(a.disqualificationReason)}</td>
        <td>{duration(moment(a.endTime).diff(moment(a.startTime))).humanize()}</td>
        <td>{moment(a.endTime).toLocaleString()}</td>
        <td>{a.suspicious ? 'Yes' : 'No'}</td>
        {Config.displaySelectedResult === 'TRUE' && <td>{a.selectedResult ? 'Yes' : 'No'}</td>}
        <td className="text-right">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={(e) => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                href="#pablo"
                onClick={(e) =>
                  window.open(`${Config.apiBaseUrl}/activities/${a.activityId}/runline`)
                }
              >
                View Activity
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={(e) => handleOnClickDeleteActivity(e, a.activityId, a.userId)}
              >
                Delete Activity
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    );
  });

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <ActivitiesHeader
                setMinDistance={setMinDistance}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                minDistance={minDistance}
                onFilterChange={getActivities}
                setMissionFilter={setMissionFilter}
                page={page}
                setPage={setPage}
                activityCount={activities.length}
                setCustomActivityFilter={setCustomActivityFilter}
                isCustomActivityFilterEnabled={customActivityFilter}
              />
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Activity Id</th>
                    <th scope="col">User</th>
                    {Config.activityType === 'TRUE' && <th scope="col">Type</th>}
                    {Config.activityType === 'TRUE' && <th scope="col">Activity Sport</th>}
                    {Config.displayCustomActivity === 'TRUE' && (
                      <th scope="col">Custom Activity Type</th>
                    )}
                    <th scope="col">Distance</th>
                    <th scope="col">Steps</th>
                    <th scope="col">Points</th>
                    <th scope="col">Disqualified</th>
                    <th scope="col">Disqualified Reason</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Date Completed</th>
                    <th scope="col">Suspicious</th>
                    {Config.displaySelectedResult === 'TRUE' && (
                      <th scope="col">Submitted Result</th>
                    )}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {loading && <LoadingSpinnerTableRow />}
                  {activityRows}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <PaginationRow
                  page={page}
                  isFirstPage={page === 1}
                  isLastPage={activities.length < PAGE_SIZE}
                  setPreviousPage={setPreviousPage}
                  setNextPage={setNextPage}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      <RemoveActivityModal onDeleteActivity={getActivities} />
    </>
  );
};

export default Activities;
