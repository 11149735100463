import { Config } from 'config';
import { useEffect, useState } from 'react';
const contentful = require('contentful');

const client = contentful.createClient({
  space: Config.contentfulSpaceId || 'undefined',
  accessToken: Config.contentfulAccessToken || 'undefined',
});

const getAllMissions = async () => {
  try {
    const missions = await client.getEntries({
      content_type: 'mission',
    });
    return missions.items;
  } catch {
    return [];
  }
};

const getAppConfiguration = async () => {
  try {
    const data = await client.getEntries({
      content_type: 'configuration',
    });
    return data.items[0];
  } catch {
    return undefined;
  }
};

export const useMissions = () => {
  const [missions, setMissions] = useState({});

  useEffect(() => {
    getAllMissions().then((missions) => {
      var missionDictionary: { [key: string]: any } = {};
      missions.forEach((mission: { sys: { id: string }; fields: any }) => {
        missionDictionary[mission.sys.id] = mission.fields;
      });
      setMissions(missionDictionary);
    });
  }, []);

  return missions;
};

export const useTicketCategories = () => {
  const [ticketCategories, setTicketCategories] = useState([]);

  useEffect(() => {
    getAppConfiguration().then((appConfiguration) => {
      const ticketCategories = appConfiguration.fields.ticketCategoryConfigurations.map(
        (ticketCategory) => ticketCategory.fields,
      );

      setTicketCategories(ticketCategories);
    });
  }, []);

  return ticketCategories;
};

export default {
  getAllMissions,
  useMissions,
  useTicketCategories,
};
