import { MissionTicket } from 'model/Ticket';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { displayGender } from 'utils/converters';

export function TicketDetailsSummary({ ticket }: { ticket: MissionTicket }) {
  return (
    <Card className="card-profile shadow">
      <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
      <CardBody className="pt-0 pt-md-4">
        <div className="text-center">
          <div className="h5 mt-4">Ticket Code: {ticket?.ticketCode}</div>
          {/**
           * TODO: Implement Redemptions after confirmation of the layout
           */}
          {/* <div className="h5 mt-4">
            Redemptions : {ticket?.ticketRedemptions}
          </div> */}
          <div className="h5 mt-4">
            Redemptions: {`${ticket?.ticketRedemptions.length}/${ticket?.redemptionLimit}`}
          </div>
          <div className="h5 mt-4">Mission ID: {ticket?.missionId}</div>
          {ticket?.teamId && <div className="h5 mt-4">Team ID: {ticket?.teamId}</div>}
          <h3>Ticket Registration Metadata:</h3>
          {ticket?.firstName && <div className="h5 mt-4">First Name: {ticket?.firstName}</div>}
          {ticket?.lastName && <div className="h5 mt-4">Last Name: {ticket?.lastName}</div>}
          {ticket?.email && <div className="h5 mt-4">Email: {ticket?.email}</div>}
          {ticket?.dateOfBirth && (
            <div className="h5 mt-4">Date of Birth: {ticket?.dateOfBirth}</div>
          )}
          {ticket?.gender !== undefined && (
            <div className="h5 mt-4">Gender: {displayGender(ticket?.gender)}</div>
          )}
          {ticket?.externalUserId && (
            <div className="h5 mt-4">External User ID: {ticket?.externalUserId}</div>
          )}
          {ticket?.externalUsername && (
            <div className="h5 mt-4">External Username: {ticket?.externalUsername}</div>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
