import React, { useEffect } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

export function SearchInput({
  onChange,
  onSearch,
  value,
  placeHolder = 'Search',
}: {
  onChange?: (text: string) => void;
  onSearch?: () => void;
  value?: string;
  placeHolder?: string;
}) {
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value) onSearch();
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [value]);

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.currentTarget?.value);
  };
  return (
    <InputGroup className="input-group-alternative">
      <InputGroupText>
        <i className="fas fa-search" />
      </InputGroupText>
      <Input onChange={_onChange} placeholder={placeHolder} type="text" />
    </InputGroup>
  );
}
