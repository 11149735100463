import { UserDetailsTicketRedemptionForm } from 'layouts/Users/UserDetails/UserDetailsTicketRedemptionForm';
import { Card, CardBody, CardHeader, Modal } from 'reactstrap';

export function RedemptionModal({ isOpen, toggleModal, userId }) {
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={() => toggleModal(!isOpen)}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <strong>Ticket Redemption</strong>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <UserDetailsTicketRedemptionForm
              isModalOpen={isOpen}
              toggleModal={toggleModal}
              userId={userId}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
