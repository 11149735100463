import { TextInput } from 'elements/TextInput';
import { Formik } from 'formik';
import { Team } from 'model/Team';
import { Button, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import TeamService from 'services/TeamService';
import { getSchema } from '../TeamsState';

export interface TeamDetailsFormProps {
  team: Team;
  teamId: string;
  setTeam: (team: Team) => void;
}

export function TeamDetailsForm({ team, teamId, setTeam }: TeamDetailsFormProps) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...team,
        profileImage: team?.profileImage,
        title: team?.title,
      }}
      validationSchema={getSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values) => {
        TeamService.editTeamProfile(
          { teamId },
          {
            title: values.title,
            profileImage: values.profileImage,
            description: '',
            coverImage: '',
          },
        )
          .then((team) => {
            TeamService.getTeamById({ teamId }).then((team) => setTeam(team));
          })
          .catch((err) => {});
      }}
    >
      {({ dirty, submitForm }) => {
        return (
          <>
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8"></Col>
                <Col className="text-right" xs="4">
                  {dirty && (
                    <Button color="primary" onClick={submitForm} size="sm">
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">Team information</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <TextInput
                        className="form-control-alternative"
                        id="input-team-name"
                        name="title"
                        label="Team Name"
                        placeholder="Team Name"
                      />
                    </Col>
                    {/* TODO: Change the image change/upload component*/}
                    <Col lg="6">
                      <TextInput
                        className="form-control-alternative"
                        id="input-profile-image"
                        name="profileImage"
                        label="Profile Image"
                        placeholder="Profile Image"
                      />
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
              </Form>
            </CardBody>
          </>
        );
      }}
    </Formik>
  );
}
