import axios from 'axios';
import Firebase from 'components/Firebase';
import { Config } from 'config';

const apiRequest = axios.create({
  baseURL: Config.apiBaseUrl,
});

apiRequest.interceptors.request.use(async (config) => {
  const currentUser = Firebase.auth.currentUser;

  if (currentUser != null) {
    const firebaseToken = await currentUser.getIdToken();

    if (config.headers) {
      config.headers.Authorization = `Bearer ${firebaseToken}`;
    }
  }

  return config;
});

export default {
  apiRequest,
};
