import DateTimePicker from 'react-datetime-picker';
import { FormGroup } from 'reactstrap';

interface Props {
  name: string;
  label: string;
  className: string;
  initialVal?: Date;
  value?: Date;
  onChange?: (val) => void;
  dependent?: boolean;
}

export function DateTimeInput({ name, label, className, value, onChange, dependent }: Props) {
  return (
    <FormGroup>
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <DateTimePicker
        className={className}
        name={name}
        onChange={(e) => {
          onChange({ target: { type: 'datetime-local', value: e, name } });
        }}
        value={value}
        disabled={dependent}
      />
    </FormGroup>
  );
}
