import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AuthHoldingPage from './AuthHoldingPage';

import 'react-activity-feed/dist/index.css';

const App = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <AuthHoldingPage />
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
