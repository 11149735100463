import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import UserService from 'services/UserService';

type DeleteUserModalProps = {
  userId: string;
  isModalOpen: boolean;
  setDeleteUserModal: (open: boolean) => void;
};

export function DeleteUserModal({ userId, isModalOpen, setDeleteUserModal }: DeleteUserModalProps) {
  const history = useHistory();

  const deleteUser = () => {
    UserService.deleteUser(userId).then((res) => {
      history.push('/admin/users');
    });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isModalOpen}
      toggle={() => setDeleteUserModal(!isModalOpen)}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <strong>Delete User</strong>
          </CardHeader>
          <CardBody className="">
            <p>Are you sure you would like to delete this user? This cannot be undone.</p>
          </CardBody>
          <div className="modal-footer flex-between">
            <Button color="danger" onClick={deleteUser}>
              Delete User
            </Button>
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={() => setDeleteUserModal(!isModalOpen)}
            >
              Cancel
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
}
