import { MissionSelector } from 'elements/MissionSelector';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';

export interface HeaderCard {
  title: string;
  value: string;
  delta?: number;
  deltaLabel?: string;
  icon: string;
}

interface Props {
  cards?: HeaderCard[];
  setMission?: (val) => void;
}

const Header = ({ cards, setMission }: Props) => {
  const headerCards = cards?.map((c, idx) => (
    <Col lg="6" xl="3" key={idx}>
      <Card className="card-stats mb-4 mb-xl-0">
        <CardBody>
          <div className="row m-0">
            <Col className="pr-0">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                {c.title}
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">{c.value}</span>
            </Col>
            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
              <i className={`fas ${c.icon}`} />
            </div>
          </div>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className={`text-${c.delta >= 0 ? 'success' : 'danger'} mr-2`}>
              <i className={`fa fa-arrow-${c.delta >= 0 ? 'up' : 'down'}`} /> {c.delta}%
            </span>{' '}
            <span className="text-nowrap">{c.deltaLabel}</span>
          </p>
        </CardBody>
      </Card>
    </Col>
  ));

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row className="mb-4">
              <Col>
                <MissionSelector onMissionUpdate={setMission} displayLabel={true} />
              </Col>
            </Row>
            {/* Card stats */}
            <Row>{headerCards}</Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
