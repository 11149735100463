import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import UserService from 'services/UserService';

export function DisqualifyUserModal({ userId, isModalOpen, setDisqualifyUserModal }) {
  const _disqualifyUser = () => {
    UserService.disqualifyUser({ userId }).then((res) => {
      window.location.reload();
    });
  };
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isModalOpen}
      toggle={() => setDisqualifyUserModal(!isModalOpen)}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <strong>Disqualify User</strong>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <p>
              Are you sure you would like to disqualify this user? This will disqualify all of their
              activities.
            </p>
            <div className="modal-footer flex-between">
              <Button color="danger" onClick={_disqualifyUser}>
                Yes
              </Button>
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => setDisqualifyUserModal(!isModalOpen)}
              >
                No
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
