import { User } from 'model/User';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import UserService from 'services/UserService';
import './UserDetailsSummary.scss';

type UserDetailsSummaryProps = {
  user: User;
  disqualifyUserModalOpen: boolean;
  setDisqualifyUserModalOpen: (modalOpen) => void;
  setMergeUserModalOpen: (modalOpen) => void;
  setDeleteUserModalOpen: (modalOpen) => void;
};

export function UserDetailsSummary({
  user,
  disqualifyUserModalOpen,
  setDisqualifyUserModalOpen,
  setMergeUserModalOpen,
  setDeleteUserModalOpen,
}: UserDetailsSummaryProps) {
  const _disqualifyUser = () => {
    setDisqualifyUserModalOpen(!disqualifyUserModalOpen);
  };

  const openDeleteUserModal = () => {
    setDeleteUserModalOpen(true);
  };

  const _mergeUser = () => {
    setMergeUserModalOpen(true);
  };

  const _getPasswordResetLink = async () => {
    const passwordResetLink = await UserService.getPasswordResetLink(user?.userId);
    if (passwordResetLink !== null) {
      await navigator.clipboard.writeText(passwordResetLink.passwordResetLink);
      alert('Copied link to clipboard');
    } else {
      alert(
        'This feature is not enabled for your application. Please contact District to enable it.',
      );
    }
  };

  return (
    <Card className="card-profile shadow user-details-summary">
      <Row className="justify-content-center">
        <Col className="order-lg-2" lg="3">
          <div className="card-profile-image">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img alt="..." className="rounded-circle" src={user?.profileImage} />
            </a>
          </div>
        </Col>
        <UncontrolledDropdown className="user-details-summary__actions-menu">
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v fa-lg" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem href="#pablo" onClick={openDeleteUserModal}>
              Delete User
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Row>
      <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
      <CardBody className="pt-0 pt-md-4">
        <div className="mt-6 text-center">
          <h3>{`${user?.firstName} ${user?.lastName}`}</h3>
          <p>
            Followers: {user?.followers} - Following: {user?.following}
          </p>
          <div className="h5 mt-4">
            <i className="ni business_briefcase-24 mr-2" />
            Activities: {user?.totalActivities}
          </div>
          <div className="h5 mt-4">
            <i className="ni business_briefcase-24 mr-2" />
            Role: {user?.role}
          </div>
          <div className="h5 mt-4">
            <i className="ni business_briefcase-24 mr-2" />
            Created: {user?.dateCreated}
          </div>
          <div className="h5 mt-4">
            <i className="ni business_briefcase-24 mr-2" />
            Points: {user?.totalPoints}
          </div>
          <div className="h5 font-weight-300">
            <i className="ni shoe mr-2" />
            Distance: {user?.totalDistance}
          </div>
          <div className="h5 font-weight-300">
            <i className="ni location_pin mr-2" />
            Steps: {user?.totalSteps}
          </div>
          <div className="h5 mt-4">
            <i className="ni business_briefcase-24 mr-2" />
            Checkpoints: {user?.totalCheckpoints} ({user?.totalUniqueCheckpoints} unique)
          </div>
          <div className="h5 mt-4">
            <Button color="primary" onClick={_getPasswordResetLink} size="sm">
              Get Password Reset Link
            </Button>
            <Button color="primary" onClick={_mergeUser} size="sm">
              Merge Another User
            </Button>
            <Button color="danger" onClick={_disqualifyUser} size="sm">
              Disqualify User
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
