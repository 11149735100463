import { User } from 'model/User';
import { EmailPattern } from 'utils/regexPatterns';
import { lazy, mixed, object, string } from 'yup';

export type UsersFormState = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  dateCreated: string;
  role: 'User' | 'Tester' | 'Admin';
};

export const defaults: User = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  dateCreated: '',
  role: 'User',
};

export function getSchema() {
  const firstName = string()
    .trim()
    .min(2, 'At least 2 characters')
    .max(20, 'At most 20 characters')
    .required('First Name is required.');
  const lastName = string()
    .trim()
    .min(2, 'At least 2 characters')
    .max(20, 'At most 20 characters')
    .required('Last Name is required.');
  const email = string()
    .required('Email is required')
    .test('email', 'Invalid email', (email) => {
      return Boolean((email || '').match(EmailPattern));
    });
  const dateOfBirth = lazy((value) => {
    if (value !== 'Invalid date') {
      return string()
        .test('test format of dateOfBirth', '', (val) => {
          // the actual return value is YYYY-MM-DD
          return true;
        })
        .matches(
          /^(19|20|21)\d\d[-](0[1-9]|1[012]|\d)[-](0[1-9]|[12][0-9]|3[01])$/,
          'Please input the correct birth of date',
        );
    } else {
      return mixed().notRequired();
    }
  });
  const dateCreated = string().matches(
    /^(20|21)\d\d[-](0[1-9]|1[012]|\d)[-](0[1-9]|[12][0-9]|3[01])$/,
    'Please input the correct date created',
  );

  return object().shape({
    firstName,
    lastName,
    email,
    dateOfBirth,
    dateCreated,
  });
}

export function getRedemptionSchema() {
  const ticketCode = string().required('Ticket Code is required.');
  const missionId = string().required('Mission ID is required.');

  return object().shape({
    ticketCode,
    missionId,
  });
}
