import Firebase from 'components/Firebase';
import Service from './index';

const apiRequest = Service.apiRequest;
export interface SocialSessionToken {
  userId: string;
  sessionToken: string;
}

async function getSocialSessionToken(): Promise<SocialSessionToken> {
  try {
    const userId = Firebase.auth.currentUser?.uid;
    const response = await apiRequest.get(`users/${userId}/socialSessionToken`);

    return response.data;
  } catch (e) {
    throw e;
  }
}

async function clearAllUserFeeds(): Promise<void> {
  try {
    await apiRequest.post(`admin/clearAllUserFeeds`);
  } catch (e) {
    throw e;
  }
}

async function deleteFeedPost(activityId: string, userId: string, feedGroup: string) {
  try {
    return await apiRequest.delete(
      `socialActivities/${activityId}?feedGroup=${feedGroup}&userId=${userId}`,
    );
  } catch (e) {
    throw e;
  }
}

export default {
  getSocialSessionToken,
  clearAllUserFeeds,
  deleteFeedPost,
};
