import { useField } from 'formik';
import { FormGroup, Input, InputProps } from 'reactstrap';

interface Props extends InputProps {
  id: string;
  name: string;
  label: string;
  className?: string;
  placeholder?: string;
  error?: string;
  options?: string[];
  readOnly?: boolean;
}

export function TextInput({
  id,
  name,
  label,
  className,
  type = 'text',
  readOnly,
  options,
  placeholder,
}: Props) {
  const [field, meta] = useField(name);
  return (
    <FormGroup>
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <Input
        className={className}
        id={id}
        type={type}
        name={field.name}
        value={field.value}
        placeholder={placeholder}
        onBlur={field.onBlur}
        onChange={field.onChange}
        readOnly={readOnly}
      />
      <div style={{ position: 'relative' }}>
        {!readOnly && meta.touched && meta.error && <p>{meta.error}</p>}
      </div>
    </FormGroup>
  );
}
