import { TextInput } from 'elements/TextInput';
import { Formik } from 'formik';
import Activity from 'model/Activity';
import { Button, Col, Form, Row } from 'reactstrap';
import TicketService from 'services/TicketService';
import { getRedemptionSchema } from '../UsersState';

export interface ActivityDetailsEditFormProps {
  activity: Activity;
  activityId: string;
  setActivity: (activity: Activity) => void;
}

interface Props {
  isModalOpen: boolean;
  toggleModal: (status: boolean) => void;
  userId: string;
}

export function UserDetailsTicketRedemptionForm({ isModalOpen, toggleModal, userId }: Props) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ticketCode: '',
        missionId: '',
      }}
      validationSchema={getRedemptionSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values) => {
        TicketService.redeemTicket({
          userId,
          missionId: values.missionId,
          ticketCode: values.ticketCode,
        })
          .then((ticket) => {
            toggleModal(!isModalOpen);
          })
          .catch((err) => {});
      }}
    >
      {({ submitForm }) => {
        return (
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <TextInput
                    className="form-control-alternative"
                    id="input-mission-id"
                    name="missionId"
                    label="Mission ID"
                    placeholder="Mission ID"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <TextInput
                    className="form-control-alternative"
                    id="input-ticket-code"
                    name="ticketCode"
                    label="Ticket Code"
                    placeholder="Ticket Code"
                  />
                </Col>
              </Row>
            </div>
            <div className="text-center">
              <Button className="my-4" onClick={submitForm} color="primary" type="button">
                Redeem
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
