import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { Config } from 'config';
import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { ActivityDetailsType } from 'recoil-state/activityState';

enum SpeedColor {
  FAST = 'red',
  MEDFAST = 'orange',
  MEDIUM = 'yellow',
  SLOW = 'green',
}

enum SpeedMetrics {
  FAST = 5.5,
  MEDIUM = 4.1,
  SLOW = 3.1,
}

/**
 * We should modify the speed metrics
 */
export function getSpeedColor(speed) {
  switch (true) {
    case speed >= SpeedMetrics.FAST:
      return SpeedColor.FAST;
    case speed >= SpeedMetrics.MEDIUM && speed < SpeedMetrics.FAST:
      return SpeedColor.MEDFAST;
    case speed >= SpeedMetrics.SLOW && speed < SpeedMetrics.MEDIUM:
      return SpeedColor.MEDIUM;
    default:
      return SpeedColor.SLOW;
  }
}

const containerStyle = {
  height: '600px',
};

export function ActivityMap({ activity }: { activity: ActivityDetailsType }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA-VdLhxZtTb9mhAjNsTZOZbBbx4NqQ5yo',
  });

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (activity && map) {
      let s = Math.min(...activity.locationStamps.map((s) => s.coords?.latitude));
      let w = Math.min(...activity.locationStamps.map((s) => s.coords?.longitude));

      let n = Math.max(...activity.locationStamps.map((s) => s.coords?.latitude));
      let e = Math.max(...activity.locationStamps.map((s) => s.coords?.longitude));

      const bounds = new window.google.maps.LatLngBounds({ lat: s, lng: w }, { lat: n, lng: e });
      map.fitBounds(bounds);
    }
  }, [activity, map]);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Card className="shadow border-0">
      {isLoaded && activity && (
        <GoogleMap
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={containerStyle}
          center={{
            lat: activity?.locationStamps[0]?.coords?.latitude || 0,
            lng: activity?.locationStamps[0]?.coords?.longitude || 0,
          }}
          zoom={14}
          // @ts-ignore
          defaultOptions={Config.googleMapConfig}
        >
          {activity?.locationStamps.map((s, index, stamps) => {
            if (index === stamps.length - 1) return null;
            return (
              <Polyline
                key={index}
                path={[
                  { lat: s.coords.latitude, lng: s.coords.longitude },
                  {
                    lat: stamps[index + 1].coords.latitude,
                    lng: stamps[index + 1].coords.longitude,
                  },
                ]}
                // defaultOptions={{ strokeColor: getSpeedColor(s.coords.speed) }}
              />
            );
          })}
        </GoogleMap>
      )}
    </Card>
  );
}
