import { MissionTicketRedemption } from 'model/Ticket';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';

interface TeamDetailsUsersPropsType {
  redemptions: MissionTicketRedemption[];
}

export function TicketRedemptionsList({ redemptions }: TeamDetailsUsersPropsType) {
  // const removeRedemption = (e, userId) => {
  //   e.stopPropagation();
  //   /** TODO: Remove redemption for User ID */
  //   // TicketService.removeRedemptionForUser({}).then(() => setTeam());
  // };
  const history = useHistory();
  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">UserId</th>
          <th scope="col">Redemption Date</th>
          {/* <th scope="col">Action</th> */}
        </tr>
      </thead>
      <tbody>
        {redemptions?.map((res, idx) => (
          <tr
            key={idx}
            onClick={(e) => history.push(`/admin/users/${res.userId}`)}
            style={{ cursor: 'pointer' }}
          >
            {/* <th scope="row">
              <Media className="align-items-center">
                <img
                  className="avatar rounded-circle mr-3"
                  alt="..."
                  src={res.profileImage}
                />
              </Media>
            </th> */}
            <td>{res.userId}</td>
            {/* <td>{res.lastName}</td> */}
            <td>{moment(res.dateRedeemed).toLocaleString()}</td>
            {/* <td>
              <Button color="danger" onClick={(e) => removeRedemption(e, res.userId)} size="sm">
                Remove
              </Button>
            </td> */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
