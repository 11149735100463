import { Config } from 'config';
import { User } from 'model/User';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { ActivityDetailsType } from 'recoil-state/activityState';
import ActivityService from 'services/ActivityService';
import { PointCountsType } from './index';

interface Props {
  activity: ActivityDetailsType;
  userProfile: User;
  activityId: string;
  modalOpen: boolean;
  setModalOpen: (status: boolean) => void;
  pointCounts: PointCountsType;
  userId: string;
}

export function ActivityDetailsCard({
  activity,
  userProfile,
  activityId,
  modalOpen,
  setModalOpen,
  pointCounts,
  userId,
}: Props) {
  const history = useHistory();
  const [csvData, setCsvData] = useState([]);
  useEffect(() => {
    setCsvData(
      activity.locationStamps.map((stamp) => ({
        uuid: stamp?.uuid,
        confidence: stamp?.activity?.confidence,
        type: stamp?.activity?.type,
        battery_charging: stamp?.battery?.is_charging ? 'True' : 'False',
        battery_level: stamp?.battery?.level,
        coords_accuracy: stamp?.coords?.accuracy,
        coords_altitude: stamp?.coords?.altitude,
        coords_heading: stamp?.coords?.heading,
        coords_latitude: stamp?.coords?.latitude,
        coords_longitude: stamp?.coords?.longitude,
        coords_speed: stamp?.coords?.speed,
        timestamp: stamp?.timestamp,
        odometer: stamp?.odometer,
      })),
    );
  }, [activity]);
  return (
    <Card className="card-profile shadow">
      <Row className="justify-content-center">
        <Col className="order-lg-2" lg="3">
          <div className="card-profile-image">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img alt="..." className="rounded-circle" src={userProfile?.profileImage} />
            </a>
          </div>
        </Col>
      </Row>
      <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
        <div className="d-flex justify-content-between">
          <Button
            className="float-right"
            color="default"
            href="#pablo"
            onClick={(e) => {
              window.open(`${Config.apiBaseUrl}/activities/${activityId}/runline`);
            }}
            size="sm"
          >
            View Runline
          </Button>
          <Button
            className="float-right"
            color="default"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              setModalOpen(!modalOpen);
            }}
            size="sm"
          >
            Update
          </Button>
          {Config.displaySelectedResult === 'TRUE' && (
            <Button
              className="float-right"
              color="default"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                ActivityService.submitActivity({ activityId }).then(() =>
                  alert('Activity submitted successfully'),
                );
              }}
              size="sm"
            >
              Submit Activity
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody className="pt-0 pt-md-4">
        <Row>
          <Col sm={2}></Col>
          <Col sm={8} className="d-flex justify-content-center mt-5">
            <CSVLink data={csvData} filename={`${activityId}_stamps.csv`}>
              Export GPS Data
            </CSVLink>
          </Col>
          <Col sm={2}></Col>
        </Row>
        <Row>
          <div className="col">
            <div className="card-profile-stats d-flex justify-content-center mt-md-1">
              <div>
                <span className="heading">{activity?.distance}m</span>
                <span className="description">Distance</span>
              </div>
              <div>
                <span className="heading">{activity?.steps}</span>
                <span className="description">Steps</span>
              </div>
              <div>
                <span className="heading">{activity?.disqualified ? 'Yes' : 'No'}</span>
                <span className="description">Disqualified</span>
              </div>
              {Config.displaySelectedResult === 'TRUE' && (
                <div>
                  <span className="heading">{activity?.selectedResult ? 'Yes' : 'No'}</span>
                  <span className="description">Submitted</span>
                </div>
              )}
            </div>
          </div>
        </Row>
        <Row>
          <div className="col">
            <div className="d-flex justify-content-center">
              <span className="heading">Total Points:</span>
              <span className="description">{pointCounts?.totalPointsCounts}</span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="heading">CheckPoints Collection Points:</span>
              <span className="description">{pointCounts?.checkPointCollectionCounts}</span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="heading">Distance Trigger Points:</span>
              <span className="description">{pointCounts?.distanceTriggerPointsCounts}</span>
            </div>
          </div>
        </Row>
        <hr className="my-4" />
        <div className="text-center">
          <h3>
            {userProfile?.firstName} {userProfile?.lastName}
          </h3>
          <div>
            <i className="ni education_hat mr-2" />
            Activity Id: {activity?.activityId}
          </div>
          <hr className="my-4" />
          <p>Start Time: {moment(activity?.startTime).toLocaleString()}</p>
          <p>End Time: {moment(activity?.endTime).toLocaleString()}</p>
          <a
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/admin/users/${userId}`);
            }}
          >
            View User
          </a>
        </div>
      </CardBody>
    </Card>
  );
}
