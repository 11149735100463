import { AppFeedback } from 'model/AppFeedback';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import UserService from 'services/UserService';
import { convertTimeToISOString } from 'utils/dateFormatters';

interface Params {
  id: string;
}

export function UserAppFeedback() {
  const { id: userId } = useParams<Params>();
  const [appFeedback, setAppFeedback] = useState<AppFeedback[]>(null);

  useEffect(() => {
    UserService.getAppFeedback(userId).then((feedback) => {
      setAppFeedback(feedback.filter((f) => !f.positive));
    });
  }, [userId]);

  return (
    <>
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Feedback Text</th>
            <th scope="col">Date Submitted</th>
            <th scope="col">Metadata</th>
          </tr>
        </thead>
        <tbody>
          {appFeedback?.map((feedback, idx) => (
            <tr key={idx} style={{ cursor: 'pointer' }}>
              <td>{feedback.feedbackText}</td>
              <td>{convertTimeToISOString(feedback.dateSubmitted)}</td>
              <td>
                <CSVLink
                  data={feedback.metadata ? Object.entries(feedback.metadata) : []}
                  filename={`feedback_${userId}_${feedback.dateSubmitted}.csv`}
                >
                  Metadata
                </CSVLink>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
