/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { UserHeader } from 'components/Headers/UserHeader';
import { AddActivityModal } from 'components/Modals/AddActivityModal';
import { DeleteUserModal } from 'components/Modals/DeleteUserModal';
import { DisqualifyUserModal } from 'components/Modals/DisqualifyUserModal';
import { MergeUserAccountModal } from 'components/Modals/MergeUserAccountModal';
import { RedemptionModal } from 'components/Modals/RedemptionModal';
import { RemoveActivityModal } from 'components/Modals/RemoveActivityModal';
import { User } from 'model/User';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import UserService, { ActivitySummaries } from 'services/UserService';
import WearablesService from 'services/WearablesService';
import { defaults } from '../UsersState';
import { UserDetailsSummary } from './UserDetailsSummary';
import { UserTabs } from './UserTabs';

interface Params {
  id: string;
}

export function UserDetails() {
  const [redemptionModalOpen, setRedemptionModalOpen] = useState(false);
  const [addActivityModalOpen, setAddActivityModalOpen] = useState(false);
  const [mergeUserAccountModalOpen, setMergeUserAccountModalOpen] = useState(false);
  const [disqualifyUserModalOpen, setDisqualifyUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const { id: userId } = useParams<Params>();
  const [user, setUser] = useState<User>(defaults);
  const [wearableProviders, setWearableProviders] = useState<any[]>([]);
  const [summaries, setSummaries] = useState<ActivitySummaries[]>([]);

  useEffect(() => {
    UserService.getUserProfile({ userId }).then((user) => {
      setUser(user);
    });
    WearablesService.getWearablesByUserId({ userId }).then((providers) => {
      setWearableProviders(providers);
    });
  }, [userId]);

  const handleOnDeleteActivity = () => {
    UserService.getUserActivitySummaries({ userId: userId }).then((summaries) => {
      setSummaries(summaries);
    });
  };

  return (
    <>
      <RedemptionModal
        isOpen={redemptionModalOpen}
        toggleModal={setRedemptionModalOpen}
        userId={userId}
      />
      <AddActivityModal
        isOpen={addActivityModalOpen}
        toggleModal={setAddActivityModalOpen}
        userId={userId}
        setUser={setUser}
        summaries={summaries}
        setSummaries={setSummaries}
      />
      <MergeUserAccountModal
        isOpen={mergeUserAccountModalOpen}
        toggleModal={setMergeUserAccountModalOpen}
        userId={userId}
      />
      <RemoveActivityModal onDeleteActivity={handleOnDeleteActivity} />
      <DisqualifyUserModal
        userId={userId}
        isModalOpen={disqualifyUserModalOpen}
        setDisqualifyUserModal={setDisqualifyUserModalOpen}
      />
      <DeleteUserModal
        userId={userId}
        isModalOpen={deleteUserModalOpen}
        setDeleteUserModal={setDeleteUserModalOpen}
      />
      <UserHeader name={`${user?.firstName} ${user?.lastName}`} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <UserDetailsSummary
              user={user}
              setDisqualifyUserModalOpen={setDisqualifyUserModalOpen}
              disqualifyUserModalOpen={disqualifyUserModalOpen}
              setMergeUserModalOpen={setMergeUserAccountModalOpen}
              setDeleteUserModalOpen={setDeleteUserModalOpen}
            />
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <UserTabs
                user={user}
                userId={userId}
                setUser={setUser}
                setRedemptionModalOpen={setRedemptionModalOpen}
                redemptionModalOpen={redemptionModalOpen}
                setAddActivityModalOpen={setAddActivityModalOpen}
                addActivityModalOpen={addActivityModalOpen}
                summaries={summaries}
                setSummaries={setSummaries}
                wearableProviders={wearableProviders}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
