import Activity from 'model/Activity';
import { PaginationQueryParams } from 'model/PaginationQueryParams';
import Service from './index';

const apiRequest = Service.apiRequest;

export interface GetActivityDataParams extends PaginationQueryParams {
  minDistance: number;
  customActivities?: boolean;
  startDate?: string;
  endDate?: string;
  missionId: string;
}

async function getAllActivities(params: GetActivityDataParams): Promise<Activity[]> {
  try {
    const response = await apiRequest.get(`activities`, {
      params,
    });

    return response.data;
  } catch (e) {
    return [];
  }
}

async function getActivityData(activityId: string): Promise<Activity | null> {
  try {
    const response = await apiRequest.get(`activities/${activityId}`);

    response.data.startTime = new Date(response.data.startTime);
    response.data.endTime = new Date(response.data.endTime);

    return response.data;
  } catch (e) {
    return null;
  }
}

async function addActivityDetails(data: any) {
  try {
    const response = await Service.apiRequest.post<Activity>(`activities`, {
      ...data,
    });
    return response.data;
  } catch (e) {
    return null;
  }
}

async function editActivityDetails(params: { activityId: string }, data: any) {
  try {
    const response = await Service.apiRequest.put<Activity>(`activities/${params.activityId}`, {
      ...data,
    });

    response.data.startTime = new Date(response.data.startTime);
    response.data.endTime = new Date(response.data.endTime);

    return response.data;
  } catch (e) {
    return null;
  }
}

async function submitActivity(params: { activityId: string }) {
  try {
    const response = await Service.apiRequest.put(`activities/${params.activityId}/selectedResult`);
    return response.data;
  } catch (e) {
    return null;
  }
}

async function deleteActivity(params: { activityId: string }) {
  try {
    const response = await Service.apiRequest.delete(`activities/${params.activityId}`);
    return response.data;
  } catch (e) {
    return null;
  }
}

export default {
  getAllActivities,
  getActivityData,
  addActivityDetails,
  editActivityDetails,
  submitActivity,
  deleteActivity,
};
