import { Modal } from 'reactstrap';

export function MakeUserAdminModal({ isOpen, toggleModal }) {
  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={() => toggleModal(!isOpen)}
    >
      <div className="modal-body p-0">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <p>You successfully made this user an admin</p>
        </div>
      </div>
    </Modal>
  );
}
