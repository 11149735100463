import classnames from 'classnames';
import { MissionTicket } from 'model/Ticket';
import { useCallback, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { TicketDetailsForm } from './TicketDetailsForm';
import { TicketRedemptionsList } from './TicketRedemptionsList';

export interface TicketTabsProps {
  ticket: MissionTicket;
  ticketCode: string;
  setTicket: (ticket: MissionTicket) => void;
}

function TabNav({ toggle, activeTab }) {
  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '1' })}
          onClick={() => {
            toggle('1');
          }}
        >
          Details
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '2' })}
          onClick={() => {
            toggle('2');
          }}
        >
          Redemptions
        </NavLink>
      </NavItem>
    </Nav>
  );
}

export function TicketTabs({ ticket, ticketCode, setTicket }: TicketTabsProps) {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab],
  );

  return (
    <div>
      <TabNav toggle={toggle} activeTab={activeTab} />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <TicketDetailsForm ticket={ticket} ticketCode={ticketCode} setTicket={setTicket} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <TicketRedemptionsList redemptions={ticket?.ticketRedemptions} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
