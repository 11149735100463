export enum DisqualificationReason {
  TooFast = 0,
  InvalidActivityDetected = 1,
  OverlappingActivity = 2,
  TooManyChallenges = 3,
}

export enum ActivityType {
  Explore = 0,
  RunChallenge = 1,
  FreeRun = 2,
  ExternalFreeRun = 3,
}

export enum ActivitySport {
  OnFoot = 0,
  Cycling = 1,
}

export function getDisqualifiedReasonLabel(reason: DisqualificationReason) {
  switch (reason) {
    case DisqualificationReason.TooFast:
      return 'Too Fast';
    case DisqualificationReason.InvalidActivityDetected:
      return 'Invalid Activity Detected';
    case DisqualificationReason.OverlappingActivity:
      return 'Overlapping Activity';
    case DisqualificationReason.TooManyChallenges:
      return 'Too Many Challenges';
    default:
      return '';
  }
}

export function getActivityTypeLabel(type: ActivityType) {
  switch (type) {
    case ActivityType.Explore:
      return 'Explore';
    case ActivityType.RunChallenge:
      return 'Run Challenge';
    case ActivityType.FreeRun:
      return 'Free Run';
    case ActivityType.ExternalFreeRun:
      return 'External Free Run';
    default:
      return '';
  }
}

export function getActivitySportLabel(type: ActivitySport) {
  switch (type) {
    case ActivitySport.OnFoot:
      return 'On foot';
    case ActivitySport.Cycling:
      return 'Cycling';
    default:
      return '';
  }
}

export const displayGender = (gender: number) => {
  switch (gender) {
    case 0:
      return '_';
    case 1:
      return 'M';
    case 2:
      return 'F';
    default:
      return '_';
  }
};
