/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Icons } from 'components/Icons';
import Activities from 'layouts/Activities';
import { ActivityDetails } from 'layouts/Activities/ActivityDetails';
import { Login } from 'layouts/Auth/Login';
import Dashboard from 'layouts/Dashboard';
import Feed from 'layouts/Feed';
import Profile from 'layouts/Profile';
import Teams from 'layouts/Teams';
import { TeamDetails } from 'layouts/Teams/TeamDetails';
import Tickets from 'layouts/Tickets';
import { TicketDetails } from 'layouts/Tickets/TicketDetails';
import Users from 'layouts/Users';
import { UserDetails } from 'layouts/Users/UserDetails';

export interface AppRoute {
  path: string;
  name: string;
  icon?: string;
  component: any;
  layout: string;
  sideHide?: boolean;
}

var routes: AppRoute[] = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/users/:id',
    name: 'User Detail',
    icon: 'ni ni-single-02 text-blue',
    component: UserDetails,
    layout: '/admin',
    sideHide: true,
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'ni ni-single-02 text-blue',
    component: Users,
    layout: '/admin',
  },
  {
    path: '/activities',
    name: 'Activities',
    icon: 'ni ni-pin-3 text-orange',
    component: Activities,
    layout: '/admin',
  },
  {
    path: '/activity/:id',
    name: 'Activity',
    icon: 'ni ni-bullet-list-67 text-red',
    component: ActivityDetails,
    layout: '/admin',
    sideHide: true,
  },
  {
    path: '/feed',
    name: 'Feed',
    icon: 'ni ni-planet text-pink',
    component: Feed,
    layout: '/admin',
  },
  {
    path: '/teams/:id',
    name: 'Team',
    icon: 'ni ni-single-02 text-yellow',
    component: TeamDetails,
    layout: '/admin',
    sideHide: true,
  },
  {
    path: '/teams',
    name: 'Teams',
    icon: 'ni ni-single-02 text-yellow',
    component: Teams,
    layout: '/admin',
  },
  {
    path: '/tickets/:ticketCode',
    name: 'Tickets',
    icon: 'ni ni-single-02 text-yellow',
    component: TicketDetails,
    layout: '/admin',
    sideHide: true,
  },
  {
    path: '/tickets',
    name: 'Tickets',
    icon: 'ni ni-basket text-green',
    component: Tickets,
    layout: '/admin',
  },
  {
    path: '/user-profile',
    name: 'Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: Profile,
    layout: '/admin',
    sideHide: true,
  },
  {
    path: '/icons', // Show icons being used in our platform
    name: 'Icons',
    icon: 'ni ni-single-02 text-yellow',
    component: Icons,
    layout: '/admin',
    sideHide: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: '/auth',
    sideHide: true,
  },
];

export default routes;
