import { useField } from 'formik';
import { FormGroup, Input, InputProps } from 'reactstrap';

interface Props extends InputProps {
  id: string;
  name: string;
  label: string;
  className?: string;
  error?: string;
  options?: { label: string; value: any }[];
  readOnly?: boolean;
  disabled?: boolean;
}

export function SelectInput({
  id,
  name,
  label,
  className,
  type,
  readOnly,
  disabled,
  options,
}: Props) {
  const [field, meta] = useField(name);
  return (
    <FormGroup>
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <Input
        className={className}
        id={id}
        type={type}
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        disabled={disabled}
      >
        <>
          {options?.map((opt, idx) => (
            <option key={idx} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </>
      </Input>
      <div style={{ position: 'relative' }}>
        {!readOnly && meta.touched && meta.error && <p>{meta.error}</p>}
      </div>
    </FormGroup>
  );
}
