import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import TeamService from 'services/TeamService';
import { defaults } from '../TeamsState';
import { TeamDetailsSummary } from './TeamDetailsSummary';
import { TeamTabs } from './TeamTabs';

interface Params {
  id: string;
}

export function TeamDetails() {
  const { id: teamId } = useParams<Params>();
  const [team, setTeam] = useState(defaults);

  useEffect(() => {
    TeamService.getTeamById({ teamId }).then((response) => {
      setTeam(response);
    });
  }, [teamId]);
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <TeamDetailsSummary team={team} />
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <TeamTabs team={team} teamId={teamId} setTeam={setTeam} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
