import { Button, Col, Row } from 'reactstrap';

interface PaginationRowProps {
  page: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  setPreviousPage: () => void;
  setNextPage: () => void;
}

const PaginationRow = ({
  page,
  isFirstPage,
  isLastPage,
  setPreviousPage,
  setNextPage,
}: PaginationRowProps) => {
  return (
    <Row>
      <Col className="text-right">
        <Button
          className="my-4"
          size="sm"
          onClick={setPreviousPage}
          color={isFirstPage ? 'tertiary' : 'primary'}
          type="button"
        >
          Previous
        </Button>
        <Button
          className="my-4"
          size="sm"
          onClick={setNextPage}
          color={isLastPage ? 'tertiary' : 'primary'}
          type="button"
        >
          Next
        </Button>
      </Col>
    </Row>
  );
};

export default PaginationRow;
