import { atom } from 'recoil';
import { LocationStamp } from 'model/Activity';

export interface AwardPointEvent {
  activeMultiplier: number;
  activityEndTime: string;
  activityId: string;
  activityStartTime: string;
  awardPointEventId: string;
  awardPointTriggerType: number;
  checkpointCollectionEventId: string;
  country: string;
  disqualified: boolean;
  eventTimestamp: string;
  metadata: object;
  points: number;
  userId: string;
}

export interface ActivityDetailsType {
  activityId?: string;
  startTime: Date;
  endTime: Date;
  distance: number;
  steps: number;
  points?: number;
  duration?: number;
  userId?: string;
  suspicious?: boolean;
  disqualified?: boolean;
  selectedResult?: boolean;
  locationStamps?: LocationStamp[];
  awardPointEvents?: AwardPointEvent[];
}

export const ActivityDetailsState = atom<ActivityDetailsType>({
  key: 'ActivityDetailsState',
  default: {
    activityId: '',
    userId: '',
    startTime: new Date(),
    endTime: new Date(),
    distance: 0,
    steps: 0,
    points: 0,
    locationStamps: [],
    awardPointEvents: [],
    duration: 0,
    disqualified: false,
    selectedResult: false,
  },
});
