import { CheckBoxInput } from 'elements/CheckBoxInput';
import { TextInput } from 'elements/TextInput';
import { Formik } from 'formik';
import { Button, Col, Form, Row } from 'reactstrap';
import UserService from 'services/UserService';
export interface MergeUserAccountsFormProps {
  userId: string;
}

interface Props {
  isModalOpen: boolean;
  toggleModal: (status: boolean) => void;
  userId: string;
}

export function MergeUserAccountForm({ isModalOpen, toggleModal, userId }: Props) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        fromUserId: '',
        toUserId: userId,
        deleteAfterMerge: false,
      }}
      onSubmit={(values) => {
        UserService.mergeUsers(values.fromUserId, values.toUserId, values.deleteAfterMerge)
          .then(() => {
            toggleModal(!isModalOpen);
          })
          .catch((err) => {});
      }}
    >
      {({ submitForm, values, handleChange }) => (
        <Form>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <div>
                  <TextInput
                    id="input-duration"
                    className="form-control-alternative"
                    name="fromUserId"
                    label="Merge From User Id"
                    placeholder="aioweurhgaoieruhgasdhflgkjahsdf"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div>
                  <CheckBoxInput
                    id="input-deleteAfterMerge"
                    className="form-control-alternative"
                    name="deleteAfterMerge"
                    label="Delete After Merge"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="text-center">
            <Button className="my-4" onClick={submitForm} color="primary" type="button">
              Merge
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
