import { LoadingSpinnerTableRow } from 'elements/LoadingSpinnerTableRow';
import PaginationRow from 'elements/PaginationRow';
import { SearchInput } from 'elements/SearchInput';
import { MissionTicket } from 'model/Ticket';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { CSVDownload, CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import { useMissions } from 'services/ContentfulService';
import TicketService from 'services/TicketService';
import { PAGE_SIZE } from 'variables/constants';

export function TicketTable() {
  const history = useHistory();
  const [searchText, setSearchText] = useState<string>();
  const missions = useMissions();
  const [tickets, setTickets] = useState([]);
  const [exportTicketsList, setExportTicketsList] = useState<MissionTicket[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getTickets = useCallback(() => {
    setLoading(true);
    TicketService.getMissionTickets({ page, pageSize: PAGE_SIZE }).then((response) => {
      setTickets(response.missionTickets);
      setLoading(false);
    });
  }, [page]);

  const getAllTickets = useCallback(() => {
    setLoading(true);
    TicketService.getMissionTickets()
      .then((response) => {
        setExportTicketsList(response.missionTickets);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const onSearch = () => {
    setLoading(true);
    TicketService.searchTickets({ query: searchText }).then((response) => {
      setTickets(response);
      setLoading(false);
    });
  };

  const setNextPage = () => {
    if (tickets.length === PAGE_SIZE) {
      setPage(page + 1);
    }
  };

  const setPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (page > 0) {
      getTickets();
    }
  }, [getTickets, page]);

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Tickets</h3>
                    {exportTicketsList.length > 0 ? (
                      <CSVLink
                        data={exportTicketsList}
                        filename={`tickets_${moment().toISOString()}.csv`}
                      >
                        Export Data
                      </CSVLink>
                    ) : (
                      <Button color="link" onClick={getAllTickets}>
                        {loading ? `Loading...` : `Fetch all ticket data`}
                      </Button>
                    )}
                  </Col>
                  <Col lg="6" xl="3">
                    <SearchInput
                      onChange={setSearchText}
                      onSearch={onSearch}
                      value={searchText}
                      placeHolder="Name, Email Address or Ticket Code"
                    />
                  </Col>
                </Row>
                {!searchText && (
                  <PaginationRow
                    page={page}
                    isFirstPage={page === 1}
                    isLastPage={tickets.length < PAGE_SIZE}
                    setPreviousPage={setPreviousPage}
                    setNextPage={setNextPage}
                  />
                )}
              </CardHeader>
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Ticket Code</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Ticket Category</th>
                    <th scope="col">Mission</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <LoadingSpinnerTableRow />}
                  {tickets?.map((res, idx) => {
                    let ticketInfo = res;
                    if (res.missionTicket) ticketInfo = res.missionTicket;
                    return (
                      <tr
                        key={idx}
                        onClick={(e) => {
                          history.push(`/admin/tickets/${ticketInfo?.ticketCode}`);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>{ticketInfo.ticketCode}</td>
                        <td>{ticketInfo.firstName}</td>
                        <td>{ticketInfo.lastName}</td>
                        <td>{ticketInfo.email}</td>
                        <td>{ticketInfo.ticketCategory}</td>
                        <td>{missions[ticketInfo.missionId]?.name ?? ticketInfo.missionId}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardHeader>
                {!searchText && (
                  <PaginationRow
                    page={page}
                    isFirstPage={page === 1}
                    isLastPage={tickets.length < PAGE_SIZE}
                    setPreviousPage={setPreviousPage}
                    setNextPage={setNextPage}
                  />
                )}
              </CardHeader>
            </Card>
          </div>
        </Row>
      </Container>

      {exportTicketsList && exportTicketsList.length > 0 && (
        <CSVDownload data={exportTicketsList} filename={`tickets_${moment().toISOString()}.csv`} />
      )}
    </>
  );
}
