import { LeaderboardType } from 'model/LeaderboardType';
import { Input } from 'reactstrap';

export type LeaderboardTypeOption = { label: string; value: LeaderboardType };

export const leaderboardTypeOptions: LeaderboardTypeOption[] = [
  { label: 'Points', value: 'points' },
  { label: 'Cumulative Points', value: 'cumulativePoints' },
  { label: 'Points Average', value: 'pointsAverage' },
  { label: 'Distance (meters)', value: 'distance' },
  { label: 'Distance Average (meters)', value: 'distanceAverage' },
  { label: 'Time (seconds)', value: 'time' },
  { label: 'Total Time (seconds)', value: 'totalTime' },
  { label: 'Steps', value: 'steps' },
  { label: 'Donations', value: 'donations' },
];

export interface Props {
  onChange: (leaderboardType: LeaderboardType, label?: string) => void;
  displayLabel?: boolean;
  defaultValueLabel?: string;
}

export function LeaderboardTypeSelector({ onChange, displayLabel, defaultValueLabel }: Props) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {displayLabel && <span style={{ paddingRight: 20 }}>Leaderboard Type: </span>}
      <Input
        className={'form-control form-control-alternative'}
        style={{ maxWidth: 250 }}
        id={'input-leaderboard-type'}
        type={'select'}
        onChange={(e) =>
          onChange(
            e.target.value as LeaderboardType,
            leaderboardTypeOptions.find((_) => _.value === e.target.value).label,
          )
        }
      >
        <>
          {leaderboardTypeOptions?.map((opt, idx) => (
            <option key={idx} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </>
      </Input>
    </div>
  );
}
