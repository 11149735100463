import { WearableProviderAccount } from 'model/User';
import { Table } from 'reactstrap';
import { convertTimeToISOString } from 'utils/dateFormatters';
import { convertWearableProvider } from './UserDetailsActivitySummaries';

interface Props {
  wearableProviders: WearableProviderAccount[];
}

export function UserWearableProviders({ wearableProviders }: Props) {
  return (
    <>
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Wearable Provider</th>
            <th scope="col">Date Linked</th>
          </tr>
        </thead>
        <tbody>
          {wearableProviders?.map((provider, idx) => (
            <tr key={idx} style={{ cursor: 'pointer' }}>
              <td>{convertWearableProvider(provider.wearableProvider)}</td>
              <td>{convertTimeToISOString(provider.dateLinked)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
